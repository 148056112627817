import { Id, Uuid } from '@nexys/material-components/dist/common/type';
import { Stateful } from '@nexys/material-components';

import * as CT from 'interface/crud/type';
import * as FeatureService from 'interface/feature/data';

export const list = async (
  lunchId: Id,
  featureId: Id
): Promise<CT.Option[]> => {
  const feature = await FeatureService.detail(featureId, lunchId);
  console.log(lunchId, featureId, feature);
  return feature.options;
};

export const insert = async (
  data: Partial<CT.Option>,
  lunchId: Id,
  featureId: Id
): Promise<{ id: Id }> => {
  try {
    console.log(data);
    const re = await Stateful.Request.post(
      `/api/lunch/features/option/insert?lid=${lunchId}&fid=${featureId}`,
      data
    );

    if (re.error) {
      throw Error(re.error);
    }

    return { id: re };
  } catch (err) {
    throw Error(err);
  }
};

export const detail = async (
  id: Id | Uuid,
  lunchId: Id,
  featureId: Id
): Promise<CT.Option> => {
  const options = await list(lunchId, featureId);
  const item = options.filter(o => o.id === Number(id))[0];
  return item;
};

export const update = async (
  data: Partial<CT.Option>,
  lunchId: Id,
  featureId: Id
): Promise<boolean> => {
  try {
    const re = await Stateful.Request.post(
      `/api/lunch/features/option/update?lid=${lunchId}&fid=${featureId}&oid=${data.id}`
    );

    if (re.error) {
      throw Error(re.error);
    }

    return true;
  } catch (err) {
    throw Error(err);
  }
};

export const deleteById = (
  optionId: Id | Uuid,
  lunchId: Id
): Promise<boolean> => {
  try {
    return Stateful.Request.get(
      `/api/lunch/features/option/delete?oid=${optionId}&lid=${lunchId}`
    );
  } catch (err) {
    throw Error(err);
  }
};
