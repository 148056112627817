import React from 'react';

import { Id } from '@nexys/material-components/dist/common/type';
import * as CrudT from '@nexys/material-components/dist/crud-simple/type';
import * as T from 'interface/feature/type';
import Options from './options';

const extras = (userId: Id, lunchId: Id): CrudT.ExtraUnit<T.Feature>[] => [
  {
    title: 'Options',
    Component: id => (
      <Options userId={userId} lunchId={lunchId} featureId={Number(id)} />
    ),
    width: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }
  }
];

export default extras;
