import React from 'react';
import Typography from '@material-ui/core/Typography';

const Cheatsheet = (): JSX.Element => {
  return (
    <div>
      <Typography>
        <strong>Smart Matching Excel Cheatsheet</strong>
      </Typography>

      <Typography>
        This cheatsheet gives quick tips to clean an excel sheet so that it can
        be imported into LunchLottery seamlessly.
      </Typography>
      <Typography>
        <strong>Index a feature column</strong>
      </Typography>
      <Typography>
        Creates indices for all distinct option of a feature.
      </Typography>

      <ol>
        <li>
          <Typography>sort feature column of interest</Typography>

          <ul>
            <li>
              <Typography>sort the WHOLE table A-Z</Typography>
            </li>
            <li>
              <Typography>
                click on <code>extend selection</code>
              </Typography>
            </li>
          </ul>
        </li>

        <li>
          <Typography>apply function to get index</Typography>

          <ul>
            <li>
              <Typography>create a new column</Typography>
            </li>
            <li>
              <Typography>
                paste the folowing function: <code> =IF(B2=B1,C1, C1+1)</code>{' '}
                (note you need to adapt the cell coordinates)
              </Typography>
            </li>
          </ul>
        </li>

        <li>
          <Typography>
            paste newly created values without the associated function
          </Typography>

          <ul>
            <li>
              <Typography>select all column</Typography>
            </li>
            <li>
              <Typography>copy</Typography>
            </li>
            <li>
              <Typography>
                paste special: <code>values</code>
              </Typography>
            </li>
          </ul>
        </li>

        <li>
          <Typography>remove column with functions</Typography>
        </li>
      </ol>

      <Typography>
        <strong>Extra functions</strong>
      </Typography>

      <ul>
        <li>
          <Typography>extract only part of the feature</Typography>

          <ul>
            <li>
              <Typography>
                <code> =LEFT(F2, 4)</code>
              </Typography>
            </li>
          </ul>
        </li>
        <li>
          <Typography>convert to a lower case string</Typography>

          <ul>
            <li>
              <Typography>
                <code> =LOWER(F2)</code>
              </Typography>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Cheatsheet;
