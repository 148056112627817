import * as CT from 'interface/crud/type';
import * as T from './type';
import * as Data from './data';

export const postProcessing = (feature: CT.Feature): T.Feature => {
  const typeId = Data.typeOptions.filter(d => d.id === feature.typeId)[0];

  return {
    ...feature,
    typeId
  };
};
