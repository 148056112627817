import React from 'react';
import { Redirect } from 'react-router-dom';
import * as Links from 'common/link';
import { Input, Button } from '@material-ui/core';
import { UI } from '@nexys/material-components';
import * as D from 'interface/lunch/data';

export default () => {
  const [val, setVal] = React.useState<'' | number>('');
  const [redirectUserId, setRedirect] =
    React.useState<undefined | number>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const handleChange = (
    v: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const x = v.target.value;
    const n = Number(x);

    if (!isNaN(n)) {
      setVal(n);
    }

    if (x === '') {
      setVal(x);
    }
  };
  const handleSubmit = async (v: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    v.preventDefault();
    const n = Number(val);

    if (!isNaN(n) && val !== '') {
      try {
        const { user_id } = await D.detail(n);

        setRedirect(user_id);
        setLoading(false);
      } catch (err) {
        alert('something went wrong: ' + JSON.stringify(err));
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  if (loading) {
    return <UI.Loader />;
  }

  if (redirectUserId && val !== '') {
    return <Redirect to={Links.App.getLunchDetail(redirectUserId, val)} />;
  }

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Input
        value={val}
        placeholder={'input here lunch id'}
        onChange={handleChange}
      />
      <Button type={'submit'} variant="outlined" color={'primary'}>
        Go
      </Button>
    </form>
  );
};
