import React, { useState } from 'react';

import { Id } from '@nexys/material-components/dist/common/type';
import { CrudSimple, Utils } from '@nexys/material-components';

import Options from './options';
import Bar from './bar';
import NetworkVizualization from './network-viz';

import DataInterface from './list/data';
import dataDef from './list/data-def';

const useStyles = Utils.makeStyles(theme => ({
  appContainer: {
    marginBottom: theme.spacing(2)
  }
}));

export default ({
  lunchId,
  userId,
  statusId
}: {
  lunchId: Id;
  userId: Id;
  statusId: Id;
}) => {
  const [reload, setReload] = useState(false);
  const classes = useStyles();

  const triggerReload = () => setReload(!reload);

  return (
    <>
      <div className={classes.appContainer}>
        <CrudSimple.Generic.App
          dataDef={dataDef(userId, lunchId)}
          Data={DataInterface(userId, lunchId)}
        />
      </div>
      <Options
        lunchId={lunchId}
        statusId={statusId}
        onImportSuccess={triggerReload}
      />
      <Bar lunchId={lunchId} />
      <NetworkVizualization lunchId={lunchId} />
    </>
  );
};
