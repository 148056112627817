import React from 'react';

const nginxCodeSnippet = `server {
  listen 443 ssl http2;
  server_name weidmatt.lunch-lottery.com;

  ssl_certificate /etc/letsencrypt/live/app.lunch-lottery.com/fullchain.pem;
  ssl_certificate_key /etc/letsencrypt/live/app.lunch-lottery.com/privkey.pem;
  ssl_protocols TLSv1.2;

  location /.well-known/acme-challenge {
    default_type "text/plain";
    root /var/www/html;
  }

  location /optin {
    proxy_set_header Content-Type application/json;
    proxy_http_version 1.1;
    proxy_pass http://lunch_admin_middleware:3000/optin;
  }

  location / {
    proxy_http_version 1.1;
    proxy_pass https://lunchlottery-weidmatt.webflow.io/;
  }
}`;

export const NginxSample = () => {
  return <DisplayCode label={'Nginx'} code={nginxCodeSnippet} />;
};

const DisplayCode = ({ label, code }: { label: string; code: string }) => {
  return (
    <>
      <h3>{label}</h3>
      <pre>{code}</pre>
    </>
  );
};

export const debugCmdCode = `
// taken from https://gist.github.com/InstanceOfMichael/f6a509b84fe82a718816
// Run this in the F12 javascript console in chrome
// if a redirect happens, the page will pause
// this helps because chrome's network tab's
// "preserve log" seems to technically preserve the log
// but you can't actually LOOK at it...
// also the "replay xhr" feature does not work after reload
// even if you "preserve log".

window.addEventListener("beforeunload", function() { debugger; }, false)`;

export const DebugCmd = () => (
  <DisplayCode code={debugCmdCode} label="Debug cmd" />
);
export default NginxSample;
