import React from 'react';

import { UI, Utils } from '@nexys/material-components';
import * as T from 'interface/lunch/type';

const useStyles = Utils.makeStyles(theme => ({
  qrContainer: {
    marginTop: theme.spacing(2)
  }
}));

const QR = ({ value }: { value: string }) => {
  const [isHidden, setHidden] = React.useState<boolean>(true);
  const classes = useStyles();

  if (isHidden) {
    return (
      <UI.Buttons.Button label={'Show QR'} onClick={() => setHidden(false)} />
    );
  }

  return (
    <>
      <UI.QrCode size={200} value={value} />

      <div className={classes.qrContainer}>
        <UI.Buttons.Button label={'Hide QR'} onClick={() => setHidden(true)} />
      </div>
    </>
  );
};

export default ({ data }: { data: T.Lunch }) => {
  const classes = useStyles();

  return (
    <>
      <UI.CopyText text={data.url.registration} />

      <div className={classes.qrContainer}>
        <QR value={data.url.registration} />
      </div>
    </>
  );
};
