//https://coolors.co/ff8360-e8e288-7dce82-3cdbd3-00fff5-8c86aa-81559b-7e3f8f-274c77-6096ba
//https://mycolor.space/?hex=%23845EC2&sub=1
export const colors = [
  "ff8360",
  "e8e288",
  "7dce82",
  "3cdbd3",
  "00fff5",
  "8c86aa",
  "81559b",
  "7e3f8f",
  "274c77",
  "6096ba",
  "845EC2",
  "D65DB1",
  "FF6F91",
].map((x) => `#` + x);

const nColor = colors.length;

export const getColorByInt = (i: number) => {
  const j = i % nColor;
  return colors[j];
};

export const colorDefault = "#97C2FC";
