import React from 'react';
import Grid from '@material-ui/core/Grid';

import { FormUIProps } from '@nexys/material-components/dist/hoc/type';
import { UI, Utils } from '@nexys/material-components';
import * as T from 'interface/lunch/type';
import Tags, { Tag } from '../settings/tags';

const useStyles = Utils.makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  bodyContainer: {
    marginTop: theme.spacing(1)
  }
}));

const {
  Banner,
  CellLayout,
  Form: { FormLayout, Wrapper, Input, Switch, Textarea }
} = UI;

const tags: Tag[] = [
  {
    label: 'Name',
    name: 'name'
  },
  {
    label: 'Meeting Place',
    name: 'meeting_point'
  },
  {
    label: 'Date',
    name: 'date'
  },
  {
    label: 'Unsubscribe Link',
    name: 'unsubscribe'
  },
  {
    label: 'Opt-Out forever',
    name: 'optOutForever'
  }
];

const FormUI = (props: FormUIProps<T.Lunch>): JSX.Element => {
  const { data, onChange, onSubmit, errors } = props;
  const classes = useStyles();

  const htmlOrBody = (): 'sendEmailHtml' | 'sendEmailBody' =>
    data.sendEmailIsHtml ? 'sendEmailHtml' : 'sendEmailBody';

  const handleInsertTag = (newContent: string) => {
    onChange({ name: htmlOrBody(), value: newContent });
  };

  return (
    <FormLayout onSubmit={onSubmit}>
      <CellLayout spacing={2} className={classes.container}>
        <Grid item xs={9}>
          <Wrapper name="sendEmailTitle" label="Email title" errors={errors}>
            <Input name="sendEmailTitle" onChange={onChange} data={data} />
          </Wrapper>

          <Wrapper
            name="sendEmailIsHtml"
            label="Is HTML email?"
            errors={errors}
            type="switch"
          >
            <Switch name="sendEmailIsHtml" onChange={onChange} data={data} />
          </Wrapper>

          <Banner.NotificationWrapper confirmationType="warning">
            You are currently editing the{' '}
            <strong>{!data.sendEmailIsHtml && 'non'} HTML</strong> version of
            the email.
          </Banner.NotificationWrapper>

          <div className={classes.bodyContainer}>
            {data.sendEmailIsHtml ? (
              <Wrapper
                name="sendEmailHtml"
                label="HTML Email body"
                errors={errors}
              >
                <Textarea
                  name="sendEmailHtml"
                  onChange={onChange}
                  data={data}
                  rows={20}
                />
              </Wrapper>
            ) : (
              <Wrapper name="sendEmailBody" label="Email body" errors={errors}>
                <Textarea
                  name="sendEmailBody"
                  onChange={onChange}
                  data={data}
                  rows={20}
                />
              </Wrapper>
            )}
          </div>
        </Grid>

        <Grid item xs={3}>
          <Tags
            content={data[htmlOrBody()] || ''}
            inputId={htmlOrBody()}
            onInsert={handleInsertTag}
            tags={tags}
          />
        </Grid>
      </CellLayout>
    </FormLayout>
  );
};

export default FormUI;
