import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';
import CodeIcon from '@material-ui/icons/Code';

import * as CrudT from '@nexys/material-components/dist/crud-simple/type';
import { Id } from '@nexys/material-components/dist/common/type';
import * as NexysUtils from '@nexys/utils';
import { UI, Utils } from '@nexys/material-components';
import * as T from 'interface/lunch/type';
import * as Links from 'common/link';
import { IdChip, Status } from '../utils/ui';

const useStyles = Utils.makeStyles(theme => ({
  link: {
    display: 'inline-block'
  }
}));

const { Link } = UI;

interface Props {
  children: React.ReactNode;
  to: string;
}

const MyLink = ({ children, to }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.link}>
      {children}
    </Link>
  );
};

// data definition/ config
const dataDef = (userId: Id): CrudT.DataDef<T.Lunch> => {
  const urlPrefix = Links.App.lunch + '/' + userId + '/lunches';

  return {
    urlPrefix,
    title: 'Info & Participants',
    fields: [
      {
        name: 'id',
        label: 'Id',
        type: 'number',
        hide: { update: true, add: true },
        render: x => <IdChip id={x.id} />
      },
      { name: 'name', label: 'Title', type: 'string', filter: true },
      {
        name: 'emailFrom',
        label: 'From email',
        type: 'string',
        hide: { add: true }
      },
      {
        name: 'group_size',
        label: 'Group size',
        type: 'number',
        hide: { list: true }
      },
      {
        name: 'date',
        label: 'Date',
        type: 'date',
        render: x => NexysUtils.date.formatDate(x.date),
        filter: {
          type: 'string',
          func: (dataRow, value) =>
            NexysUtils.date.formatDate(dataRow.date).includes(value)
        },
        inputProps: {
          shouldReturnDateObj: true
        }
      },
      {
        name: 'time',
        label: 'Time',
        type: 'string',
        render: x => x.time,
        hide: { list: true },
        inputProps: {
          placeholder: 'HH:mm (24-hour format)'
        }
      },
      {
        name: 'meeting_point',
        label: 'Meeting point',
        type: 'string',
        filter: true
      },
      {
        name: 'nbParticipants',
        hide: { add: true },
        label: 'Number of participants',
        type: 'number',
        filter: false
      },
      {
        name: 'id',
        label: '',
        type: 'string',
        render: x => (
          <>
            <span style={{ display: 'block', marginBottom: 12 }}>
              <MyLink to={`${urlPrefix}/${x.id}/settings`}>
                <SettingsIcon
                  style={{
                    fontSize: '1rem',
                    marginRight: 8,
                    verticalAlign: 'middle'
                  }}
                />
                Settings
              </MyLink>
            </span>
            <span style={{ display: 'block', marginBottom: 12 }}>
              <MyLink to={`${urlPrefix}/${x.id}/send-emails`}>
                <EmailIcon
                  style={{
                    fontSize: '1rem',
                    marginRight: 8,
                    verticalAlign: 'middle'
                  }}
                />
                Send Emails
              </MyLink>
            </span>
            <MyLink to={`${urlPrefix}/${x.id}/webflow`}>
              <CodeIcon
                style={{
                  fontSize: '1rem',
                  marginRight: 8,
                  verticalAlign: 'middle'
                }}
              />
              Generate webflow code
            </MyLink>
          </>
        ),
        hide: { list: true, add: true, update: true }
      },
      {
        name: 'status',
        label: 'Status',
        type: 'number',
        render: x => <Status statusId={x.status} />,
        hide: { add: true, update: true, detail: true }
      }
    ],
    listConfig: { search: true }
  };
};

export default dataDef;
