import NexysUtils from '@nexys/utils';

import * as CT from 'interface/crud/type';
import * as Data from './data';
import * as T from './type';
import * as dateFnsTz from 'date-fns-tz';

export const timezoneDefault: T.Timezone = 'Europe/Zurich';

export const toDateIso = (
  time?: string,
  date?: Date,
  timezone: T.Timezone = 'Europe/Zurich'
) => {
  if (!date) {
    throw Error('no date');
  }

  const [hours, minutes] = time!.split(':');
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));

  const d = dateFnsTz.zonedTimeToUtc(date, timezone);
  const iso = d.toISOString();

  return iso;
};

export const toDateUTC = (
  datestring: string,
  timezone: T.Timezone = 'Europe/Zurich'
) => dateFnsTz.zonedTimeToUtc(datestring, timezone);

export const toDateTZ = (
  datestring: string,
  timezone: T.Timezone = 'Europe/Zurich'
) => dateFnsTz.utcToZonedTime(datestring, timezone);

export const postProcessing = (lunch: CT.Lunch): T.Lunch => {
  const lang = Data.languageOptions.filter(l => l.uuid === lunch.lang)[0];

  const sendOutDate = toDateTZ(lunch.date.sendOut);
  const closeDate = toDateTZ(lunch.date.close);

  const sendOutTime = NexysUtils.date.formatTime(sendOutDate).substr(0, 5);
  //console.log(lunch.date.sendOut);
  //console.log(sendOutDate);
  //console.log(sendOutTime);

  return {
    ...lunch,
    isLunchExpired: lunch.date.isLunchExpired,
    date: new Date(lunch.date.date),
    time: NexysUtils.date.formatTime(lunch.date.date).substr(0, 5),
    sendOutDate,
    sendOutTime,
    closeDate,
    closeTime: NexysUtils.date.formatTime(closeDate).substr(0, 5),
    added: new Date(lunch.date.added),
    settingsTitle: lunch.email.title,
    settingsBody: lunch.email.body,
    settingsHtml: lunch.email.html,
    settingsIsHtml: lunch.email.isHtml,
    sendEmailTitle: lunch.sendEmail.title,
    sendEmailBody: lunch.sendEmail.body,
    sendEmailHtml: lunch.sendEmail.html,
    sendEmailIsHtml: lunch.sendEmail.isHtml,
    lang
  };
};
