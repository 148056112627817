import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';

import * as Links from 'common/link';

import ByEmail from './list-by-user/index';

import Main from './main';

function App(props: RouteComponentProps) {
  return (
    <Switch>
      <Route path={Links.App.lunchMain} component={Main} props={props} />
      <Route
        path={Links.App.lunchByUserPrefix(':userId' as any as number)}
        component={ByEmail}
        props={props}
      />
      <Route
        exact={true}
        path={Links.App.lunch}
        component={() => <Redirect to={Links.App.lunchMain} />}
      />
    </Switch>
  );
}

export default App;
