import React from 'react';
import { Route, Link } from 'react-router-dom';

import { CrudSimple, UI } from '@nexys/material-components'; //'../lib/crud-simple/generic/app';
import * as CrudT from '@nexys/material-components/dist/crud-simple/type';
import * as T from 'interface/user/type';
import * as Data from 'interface/user/data';
import * as Links from 'common/link';

const { Layout } = UI;

// data interface (CRUD)
const DataInterface: CrudT.DataInterface<T.User> = {
  list: Data.list,
  detail: Data.detail,
  insert: Data.insert,
  update: Data.update,
  deleteById: Data.deleteById
};

// data defintiion/ config
const dataDef: CrudT.DataDef<T.User> = {
  urlPrefix: Links.App.user,
  title: 'Users',
  fields: [
    { name: 'email', label: 'Email', type: 'string', filter: true },
    { name: 'firstName', label: 'First Name', type: 'string', filter: true },
    { name: 'lastName', label: 'Last Name', type: 'string', filter: true },
    {
      name: 'password1',
      label: 'Password',
      type: 'string',
      hide: { list: true, detail: true }
    },
    {
      name: 'password2',
      label: 'Password Confirmation',
      type: 'string',
      hide: { list: true, detail: true }
    },
    {
      name: 'id',
      label: 'Link',
      type: 'string',
      render: x => (
        <ul>
          <li>
            <Link to={Links.App.lunchByUser(x.id)}>Link to lunches</Link>
          </li>
          <li>
            <Link to={Links.App.luncUserCreate(x.id)}>Create a lunch</Link>
          </li>
        </ul>
      ),
      hide: { list: true, detail: false, update: false, add: false }
    }
  ],
  listConfig: { search: true },
  displayType: { delete: false }
};

export default () => (
  <>
    <Route exact path={Links.App.user}>
      <Layout.Title title="Users" type="pageTitle" />
    </Route>

    <CrudSimple.Generic.App dataDef={dataDef} Data={DataInterface} />
  </>
);
