import React from 'react';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';

import { Id } from '@nexys/material-components/dist/common/type';
import * as CrudT from '@nexys/material-components/dist/crud-simple/type';
import * as T from 'interface/lunch/type';
import * as Links from 'common/link';
import { ParticipantFeature } from 'interface/crud/type';
import OptOutLinks from './opt-out-links';

const Feature = ({ f }: { f: ParticipantFeature }) => (
  <Chip
    key={`feature-${f.feature_id}`}
    label={f.name + ': ' + f.optionName}
    size="small"
    style={{ margin: '0 5px 5px 0' }}
    component="span"
  />
);

// data definition/ config
const dataDef = (userId: Id, lunchId: Id): CrudT.DataDef<T.Participant> => ({
  urlPrefix: Links.App.getLunchDetail(userId, lunchId),
  fields: [
    {
      name: 'email',
      label: 'Email',
      type: 'string',
      filter: true,
      hide: { update: true },
      render: x => <Link to={`/app/user/${x.id}/detail`}>{x.email}</Link>
    },
    {
      name: 'firstName',
      label: 'First Name',
      type: 'string',
      filter: true,
      hide: { update: true }
    },
    {
      name: 'lastName',
      label: 'Last Name',
      type: 'string',
      filter: true,
      hide: { update: true }
    },
    {
      name: 'groupNumber',
      label: 'Group Number',
      type: 'number',
      filter: true,
      render: x => x.groupNumber || '-'
    },
    {
      name: 'features',
      label: 'Features',
      type: 'string',
      render: x => x.features.map(f => <Feature f={f} />),
      hide: { update: true, add: true }
    },
    {
      name: 'id',
      label: 'Opt Out link',
      type: 'string',
      hide: { update: true, add: true, list: true },
      render: x => <OptOutLinks userId={x.id} lunchId={x.lunch.lunch_id} />
    }
  ],
  listConfig: { search: true },
  displayType: { add: false, delete: true },
  detailUrlPrefix: 'participants'
});

export default dataDef;
