import React from 'react';
import { Redirect } from 'react-router-dom';

import { UI, Utils } from '@nexys/material-components';
import { appTitle } from 'config';
import * as D from 'interface/login';
import bgImage from './login-bg.jpg';

import SSOList from './sso-list';

const { Layout, Business } = UI;

const useStyles = Utils.makeStyles(theme => ({
  container: {
    textAlign: 'center'
  },
  divider: {
    margin: theme.spacing(4, 0)
  }
}));

export default () => {
  const [redirect, setRedirect] = React.useState<string | undefined>(undefined);
  const classes = useStyles();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const firstName = urlParams.get('firstName') || urlParams.get('First_Name');
  const lastName = urlParams.get('lastName') || urlParams.get('Last_Name');

  if (firstName && lastName) {
    D.authenticate(firstName, lastName);
    setRedirect('/app');
  }

  return (
    <Layout.Login title={appTitle} backgroundImage={bgImage} layout="left">
      <Business.Login.Error name="notifLogout">
        You were successfully logged out.
      </Business.Login.Error>

      <div className={classes.container}>
        <Layout.Title title={`Login to ${appTitle}`} type="groupTitle" />
        <SSOList />
      </div>
    </Layout.Login>
  );
};
