import React from 'react';
import { UI } from '@nexys/material-components';
import { appTitle } from 'config/index';

const gitVersion: string = process.env.REACT_APP_VERSION || 'version unset';
const gitVersionLink: string =
  'https://github.com/LunchLottery/admin_client/releases/tag/' + gitVersion;

const Footer = () => (
  <UI.Nav.Footer.Footer>
    <UI.Nav.Footer.Copyright
      sha={{ sha: gitVersion, link: gitVersionLink }}
      company={appTitle}
    />
  </UI.Nav.Footer.Footer>
);

export default Footer;
