import * as CT from 'interface/crud/type';
import * as T from './type';

export const lunchGroups: T.LunchGroup[] = [
  {
    n: 1,
    userId: 1,
    email: 'john.doe@example.com',
    dates: {
      date: new Date(),
      close: new Date(),
      sendOut: new Date()
    }
  }
];

export const l = [
  {
    id: 405,
    date: {
      date: '2018-04-05T03:00:00Z',
      sendOut: '2018-04-05T05:00:00Z',
      close: '2018-04-05T03:00:00Z',
      added: '2018-04-04T21:26:10Z',
      isLunchExpired: true
    },
    meeting_point: 'Test',
    group_size: 2,
    type_id: 1,
    name: 'Test',
    user_id: 1770,
    key: 'pGRXPbM62K7pdgeN',
    lang: 'en',
    emailFrom: 'info@lunch-lottery.com',
    location_id: 1,
    status: 3,
    url: { registration: 'tbd', unsubscribe: '', optOut: '' },
    nbParticipants: 4,
    fromEmail: 'test@test.com'
  },
  {
    id: 406,
    date: {
      date: '2018-04-08T03:00:00Z',
      sendOut: '2018-04-08T05:00:00Z',
      close: '2018-04-08T03:00:00Z',
      added: '2018-04-07T22:58:33Z',
      isLunchExpired: true
    },
    meeting_point: 'To be determined individually 1',
    group_size: 2,
    type_id: 1,
    name: 'Test 1',
    user_id: 1770,
    key: 'JTooRpo5bA4f0pif',
    lang: 'en',
    emailFrom: 'info@lunch-lottery.com',
    location_id: 1,
    status: 3,
    url: { registration: 'tbd', unsubscribe: '', optOut: '' },
    nbParticipants: 6,
    fromEmail: 'test@test.com'
  },
  {
    id: 407,
    date: {
      date: '2018-04-07T23:12:00Z',
      sendOut: '2018-04-07T23:13:00Z',
      close: '2018-04-07T23:12:00Z',
      added: '2018-04-07T23:02:54Z',
      isLunchExpired: true
    },
    meeting_point: 'Test',
    group_size: 2,
    type_id: 1,
    name: 'Test',
    user_id: 1770,
    key: 'sePYSd8jk3ScGYC3',
    lang: 'en',
    emailFrom: 'info@lunch-lottery.com',
    location_id: 1,
    status: 3,
    url: { registration: 'tbd', unsubscribe: '', optOut: '' },
    nbParticipants: 4
  },
  {
    id: 408,
    date: {
      date: '2018-04-29T23:12:00Z',
      sendOut: '2018-04-29T23:13:00Z',
      close: '2018-04-29T23:12:00Z',
      added: '2018-04-07T23:15:36Z',
      isLunchExpired: true
    },
    meeting_point: 'Test',
    group_size: 2,
    type_id: 1,
    name: 'Test',
    user_id: 1770,
    key: 'QRvYzE0ySgQ0EOBS',
    lang: 'en',
    emailFrom: 'info@lunch-lottery.com',
    location_id: 1,
    status: 1,
    url: { registration: 'tbd', unsubscribe: '', optOut: '' },
    nbParticipants: 4
  },
  {
    id: 409,
    date: {
      date: '2019-10-04T03:00:00Z',
      sendOut: '2019-10-04T05:00:00Z',
      close: '2019-10-04T03:00:00Z',
      added: '2018-04-07T23:23:59Z',
      isLunchExpired: true
    },
    meeting_point: 'To be determined individually',
    group_size: 2,
    type_id: 1,
    name: 'Admin Lunch',
    user_id: 1770,
    key: '50Asyzpr95WyXAt5',
    lang: 'en',
    emailFrom: 'info@lunch-lottery.com',
    location_id: 1,
    status: 1,
    url: { registration: 'tbd', unsubscribe: '', optOut: '' },
    nbParticipants: 6
  },
  {
    id: 410,
    date: {
      date: '2018-04-14T03:00:00Z',
      sendOut: '2018-04-14T05:00:00Z',
      close: '2018-04-14T03:00:00Z',
      added: '2018-04-07T23:26:34Z',
      isLunchExpired: true
    },
    meeting_point: 'To be determined individually',
    group_size: 2,
    type_id: 1,
    name: 'Test',
    user_id: 1770,
    key: 'UfrXE0PY7enO9JLu',
    lang: 'en',
    emailFrom: 'info@lunch-lottery.com',
    location_id: 1,
    status: 1,
    url: { registration: 'tbd', unsubscribe: '', optOut: '' },
    nbParticipants: 5
  },
  {
    id: 411,
    date: {
      date: '2018-05-01T04:00:00Z',
      sendOut: '2018-05-01T07:00:00Z',
      close: '2018-05-01T04:00:00Z',
      added: '2018-04-07T23:27:25Z',
      isLunchExpired: true
    },
    meeting_point: 'To be determined individually',
    group_size: 2,
    type_id: 1,
    name: 'Test',
    user_id: 1770,
    key: 'fGw1KklrzLkShjPv',

    lang: 'en',
    emailFrom: 'info@lunch-lottery.com',
    location_id: 1,
    status: 1,
    url: { registration: 'tbd', unsubscribe: '', optOut: '' },
    nbParticipants: 5
  },
  {
    id: 412,
    date: {
      date: '2018-05-07T04:00:00Z',
      sendOut: '2018-05-07T07:00:00Z',
      close: '2018-05-07T04:00:00Z',
      added: '2018-04-07T23:34:36Z',
      isLunchExpired: true
    },
    meeting_point: 'To be determined individually',
    group_size: 2,
    type_id: 1,
    name: 'TEST',
    user_id: 1770,
    key: 'BP1n2cRENGxqsAfW',

    lang: 'en',
    emailFrom: 'info@lunch-lottery.com',
    location_id: 1,
    status: 3,
    url: { registration: 'tbd', unsubscribe: '', optOut: '' },
    nbParticipants: 5
  },
  {
    id: 413,
    date: {
      date: '2018-04-07T23:42:00Z',
      sendOut: '2018-04-07T23:43:00Z',
      close: '2018-04-07T23:42:00Z',
      added: '2018-04-07T23:39:23Z',
      isLunchExpired: true
    },
    meeting_point: 'To be determined individually',
    group_size: 2,
    type_id: 1,
    name: 'TEST1',
    user_id: 1770,
    key: 'detpejEvrleGcwOJ',

    lang: 'en',
    emailFrom: 'info@lunch-lottery.com',
    location_id: 1,
    status: 3,
    url: { registration: 'tbd', unsubscribe: '', optOut: '' },
    nbParticipants: 5
  },
  {
    id: 414,
    date: {
      date: '2018-04-08T03:00:00Z',
      sendOut: '2018-04-08T05:00:00Z',
      close: '2018-04-08T03:00:00Z',
      added: '2018-04-07T23:50:34Z',
      isLunchExpired: true
    },
    meeting_point: 'Test',
    group_size: 2,
    type_id: 1,
    name: 'Test',
    user_id: 1770,
    key: 'LlwYHR1pkoACRRyR',

    lang: 'en',
    emailFrom: 'info@lunch-lottery.com',
    location_id: 1,
    status: 3,
    url: { registration: 'tbd', unsubscribe: '', optOut: '' },
    nbParticipants: 4
  },
  {
    id: 415,
    date: {
      date: '2018-04-12T03:00:00Z',
      sendOut: '2018-04-12T05:00:00Z',
      close: '2018-04-12T03:00:00Z',
      added: '2018-04-11T21:37:43Z',
      isLunchExpired: true
    },
    meeting_point: 'Why is this',
    group_size: 2,
    type_id: 1,
    name: 'This is one',
    user_id: 1770,
    key: 'xb37N64xremYCQhS',

    lang: 'en',
    emailFrom: 'info@lunch-lottery.com',
    location_id: 1,
    status: 3,
    url: { registration: 'tbd', unsubscribe: '', optOut: '' },
    nbParticipants: 6
  }
];

// note this is depenedent on `lunchId`
export const detail: CT.Lunch = {
  id: 58,
  date: {
    date: '2014-07-31T23:25:00Z',
    sendOut: '2014-07-31T22:25:00Z',
    close: '2014-07-31T21:25:00Z',
    added: '2014-07-20T15:25:29Z',
    isLunchExpired: true
  },
  meeting_point: 'London',
  group_size: 2,
  type_id: 3,
  name: 'mon premier lunch',
  user_id: 51,
  comment: 'Descriptiongh',
  key: 'aej0G0p00Y9A2Q1z',
  email: {
    title: 'Das Los hat entschieden... / The LunchLottery has been decided',
    body:
      'Hallo {{name}} / for English see below\n\nshow databases;r die LunchLottery am {{date}} Uhr angemeldet.\nshow databases;r deine Teilnahme.\n\nDas Los hat entschieden: Du triffst dich mit:\n{{group}}\n\nIhr seid Gruppe Nummer {{number}}.\n\n\nTreffpunkt: {{meeting_point}}\n\nshow databases;nscht,\n\nDein LunchLottery Team\n\nP.S. Wie ihr eure Mittagspause gestaltet, knnnnnnnt ihr in der Gruppe bestimmen. Lust auf Migros oder doch ein Sandwich aus der Bckerei? Lieber frische Luft an der Limmat schnappen oder im 4.Obergeschoss etwas Leckeres zusammen kochen? Ihr entscheidet.\n\nHi {{name}}\n\nThanks for your participation!\nExciting news: The LunchLottery has randomly generated fors lunch groups! You will meet with the following participant(s):today\n\n{{group}}\n\nYou are group #{{number}}.\n\nThe lunch takes place on {{date}}.\n\nThe meeting point is: {{meeting_point}}\n\nEnjoy your lunch break!\n\nAll the best,\nLunchLottery Team\n\nP.S. Where you have lunch is your Teams choice. Feel like Migros or rather want to grab a sandwich from the bakery? In the mood for some fresh air by the Limmat or prefer cooking in our kitchen on the fourth floor? Teams entirely up to you.',
    html: '',
    isHtml: false
  },
  invitation_text:
    'Melden Sie sich an, um an dieser LunchLottery teilzunehmen. LunchLottery wird Ihnen kurz vor dem Lunch zufllig einen teilnehmenden Kollegen zulosen. Viel Spass!',
  sendEmail: {
    title: '',
    body: '',
    html: '',
    isHtml: false
  },
  lang: 'de',
  location_id: 1,
  status: 1,
  url: {
    registration:
      'https://app.lunch-lottery.com/lunch/58/aej0G0p00Y9A2Q1z/view?lang=de',
    unsubscribe: '',
    optOut: ''
  },
  emailFrom: 'test@test.com',
  nbParticipants: 3
  //emailFrom: 'test@test.com'
};

// note: this is dependent on `lunchId`
export const participants: CT.Participant[] = [
  {
    lunch: {
      id: 114,
      user_id: 15,
      lunch_id: 58,
      group_idx: 1,
      date_added: 1417212000000
    },
    id: 15,
    email: 'jboissard@gmail.com',
    firstName: 'John Boissard',
    features: []
  },
  {
    lunch: {
      id: 117,
      user_id: 55,
      lunch_id: 58,
      date_added: 1417212000000
    },
    id: 55,
    email: 'rando@gmail.com',
    firstName: 'rando@gmail.com',
    features: []
  },
  {
    lunch: {
      id: 121,
      user_id: 64,
      lunch_id: 58,
      date_added: 1417212000000
    },
    id: 64,
    email: 'dgdf@rhdf.com',
    firstName: 'dgdf@rhdf.com',
    features: []
  }
];

export const list: CT.Lunch[] = [
  {
    id: 58,
    date: {
      date: '2014-07-31T23:25:00Z',
      sendOut: '2014-07-31T22:25:00Z',
      close: '2014-07-31T21:25:00Z',
      added: '2014-07-20T15:25:29Z',
      isLunchExpired: true
    },
    meeting_point: 'London',
    group_size: 2,
    type_id: 3,
    name: 'mon premier lunch',
    user_id: 51,
    comment: 'Descriptiongh',
    key: 'aej0G0p00Y9A2Q1z',
    email: {
      title: 'Das Los hat entschieden... / The LunchLottery has been decided',
      body:
        'Hallo {{name}} / for English see below\n\nshow databases;r die LunchLottery am {{date}} Uhr angemeldet.\nshow databases;r deine Teilnahme.\n\nDas Los hat entschieden: Du triffst dich mit:\n{{group}}\n\nIhr seid Gruppe Nummer {{number}}.\n\n\nTreffpunkt: {{meeting_point}}\n\nshow databases;nscht,\n\nDein LunchLottery Team\n\nP.S. Wie ihr eure Mittagspause gestaltet, knnnnnnnt ihr in der Gruppe bestimmen. Lust auf Migros oder doch ein Sandwich aus der Bckerei? Lieber frische Luft an der Limmat schnappen oder im 4.Obergeschoss etwas Leckeres zusammen kochen? Ihr entscheidet.\n\nHi {{name}}\n\nThanks for your participation!\nExciting news: The LunchLottery has randomly generated fors lunch groups! You will meet with the following participant(s):today\n\n{{group}}\n\nYou are group #{{number}}.\n\nThe lunch takes place on {{date}}.\n\nThe meeting point is: {{meeting_point}}\n\nEnjoy your lunch break!\n\nAll the best,\nLunchLottery Team\n\nP.S. Where you have lunch is your Teams choice. Feel like Migros or rather want to grab a sandwich from the bakery? In the mood for some fresh air by the Limmat or prefer cooking in our kitchen on the fourth floor? Teams entirely up to you.',
      html: '',
      isHtml: false
    },
    invitation_text:
      'Melden Sie sich an, um an dieser LunchLottery teilzunehmen. LunchLottery wird Ihnen kurz vor dem Lunch zufllig einen teilnehmenden Kollegen zulosen. Viel Spass!',
    sendEmail: {
      title: '',
      body: '',
      html: '',
      isHtml: false
    },
    lang: 'de',
    location_id: 1,
    status: 1,
    url: {
      registration:
        'https://app.lunch-lottery.com/lunch/58/aej0G0p00Y9A2Q1z/view?lang=de',
      unsubscribe: '',
      optOut: ''
    },
    nbParticipants: 3,
    emailFrom: 'test@test.com'
  },
  {
    id: 59,
    date: {
      date: '2014-07-21T08:00:00Z',
      sendOut: '2014-07-21T07:00:00Z',
      close: '2014-07-21T06:00:00Z',
      added: '2014-07-21T04:19:47Z',
      isLunchExpired: true
    },
    meeting_point: 'Entrance',
    group_size: 2,
    type_id: 1,
    name: 'Network',
    user_id: 56,
    key: 'qHiUTJQj6tQwdhkJ',
    email: {
      title: 'Das Los hat entschieden... / The LunchLottery has been decided',
      body:
        'Hallo {{name}} / for English see below\n\nshow databases;r die LunchLottery am {{date}} Uhr angemeldet.\nshow databases;r deine Teilnahme.\n\nDas Los hat entschieden: Du triffst dich mit:\n{{group}}\n\nIhr seid Gruppe Nummer {{number}}.\n\n\nTreffpunkt: {{meeting_point}}\n\nshow databases;nscht,\n\nDein LunchLottery Team\n\nP.S. Wie ihr eure Mittagspause gestaltet, knnnnnnnt ihr in der Gruppe bestimmen. Lust auf Migros oder doch ein Sandwich aus der Bckerei? Lieber frische Luft an der Limmat schnappen oder im 4.Obergeschoss etwas Leckeres zusammen kochen? Ihr entscheidet.\n\nHi {{name}}\n\nThanks for your participation!\nExciting news: The LunchLottery has randomly generated fors lunch groups! You will meet with the following participant(s):today\n\n{{group}}\n\nYou are group #{{number}}.\n\nThe lunch takes place on {{date}}.\n\nThe meeting point is: {{meeting_point}}\n\nEnjoy your lunch break!\n\nAll the best,\nLunchLottery Team\n\nP.S. Where you have lunch is your Teams choice. Feel like Migros or rather want to grab a sandwich from the bakery? In the mood for some fresh air by the Limmat or prefer cooking in our kitchen on the fourth floor? Teams entirely up to you.',
      html: '',
      isHtml: false
    },
    invitation_text:
      'Melden Sie sich an, um an dieser LunchLottery teilzunehmen. LunchLottery wird Ihnen kurz vor dem Lunch zufllig einen teilnehmenden Kollegen zulosen. Viel Spass!',
    sendEmail: {
      title: '',
      body: '',
      html: '',
      isHtml: false
    },
    lang: 'de',
    location_id: 1,
    status: 1,
    url: {
      registration:
        'https://app.lunch-lottery.com/lunch/59/qHiUTJQj6tQwdhkJ/view?lang=de',
      unsubscribe: '',
      optOut: ''
    },
    nbParticipants: 1,
    emailFrom: 'test@test.com'
  },
  {
    id: 60,
    date: {
      date: '2014-07-23T20:00:00Z',
      sendOut: '2014-07-23T19:00:00Z',
      close: '2014-07-23T18:00:00Z',
      added: '2014-07-22T12:14:11Z',
      isLunchExpired: true
    },
    meeting_point: 'In the park',
    group_size: 4,
    type_id: 2,
    name: 'Free innovation',
    user_id: 56,
    comment: 'Get to know your colleagues and exchange ideas.',
    key: 'sQmj6JRSH7pG614w',
    email: {
      title: 'Das Los hat entschieden... / The LunchLottery has been decided',
      body:
        'Hallo {{name}} / for English see below\n\nshow databases;r die LunchLottery am {{date}} Uhr angemeldet.\nshow databases;r deine Teilnahme.\n\nDas Los hat entschieden: Du triffst dich mit:\n{{group}}\n\nIhr seid Gruppe Nummer {{number}}.\n\n\nTreffpunkt: {{meeting_point}}\n\nshow databases;nscht,\n\nDein LunchLottery Team\n\nP.S. Wie ihr eure Mittagspause gestaltet, knnnnnnnt ihr in der Gruppe bestimmen. Lust auf Migros oder doch ein Sandwich aus der Bckerei? Lieber frische Luft an der Limmat schnappen oder im 4.Obergeschoss etwas Leckeres zusammen kochen? Ihr entscheidet.\n\nHi {{name}}\n\nThanks for your participation!\nExciting news: The LunchLottery has randomly generated fors lunch groups! You will meet with the following participant(s):today\n\n{{group}}\n\nYou are group #{{number}}.\n\nThe lunch takes place on {{date}}.\n\nThe meeting point is: {{meeting_point}}\n\nEnjoy your lunch break!\n\nAll the best,\nLunchLottery Team\n\nP.S. Where you have lunch is your Teams choice. Feel like Migros or rather want to grab a sandwich from the bakery? In the mood for some fresh air by the Limmat or prefer cooking in our kitchen on the fourth floor? Teams entirely up to you.',
      html: '',
      isHtml: false
    },
    invitation_text:
      'Melden Sie sich an, um an dieser LunchLottery teilzunehmen. LunchLottery wird Ihnen kurz vor dem Lunch zufllig einen teilnehmenden Kollegen zulosen. Viel Spass!',
    sendEmail: {
      title: '',
      body: '',
      html: '',
      isHtml: false
    },
    lang: 'de',
    location_id: 1,
    status: 1,
    url: {
      registration:
        'https://app.lunch-lottery.com/lunch/60/sQmj6JRSH7pG614w/view?lang=de',
      unsubscribe: '',
      optOut: ''
    },
    nbParticipants: 2,
    emailFrom: 'test@test.com'
  }
];
