import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SigninIcon from '@material-ui/icons/LockOpen';

const ssoClients = [
  { url: '/api/sso/url', label: 'Sign in with Google' },
  { url: '/api/sso/zoho/url', label: 'Sign in with Zoho' }
];

const SSOList = () => (
  <List component="nav" aria-label="main mailbox folders">
    {ssoClients.map((sso, i) => (
      <ListItem key={i} button component={'a'} href={sso.url}>
        <ListItemIcon>
          <SigninIcon />
        </ListItemIcon>
        <ListItemText primary={sso.label} />
      </ListItem>
    ))}
  </List>
);

export default SSOList;
