import React from 'react';

import * as T from 'interface/lunch/type';

import { UI } from '@nexys/material-components';

import NUtils from '@nexys/utils';

const Status = ({ data }: { data: T.Lunch }) => {
  if (data.status === 1) {
    return (
      <UI.Banner.NotificationWrapper>
        Your lunch is ready and will be submitted at{' '}
        {NUtils.date.formatTime(data.sendOutDate)} on{' '}
        {NUtils.date.formatDate(data.sendOutDate)}{' '}
      </UI.Banner.NotificationWrapper>
    );
  }

  if (data.status === 2) {
    return (
      <UI.Banner.NotificationWrapper confirmationType={'warning'}>
        Initial groups have been assigned, but emails have not yet been sent
        out.
      </UI.Banner.NotificationWrapper>
    );
  }

  if (data.status === 3) {
    return (
      <UI.Banner.NotificationWrapper confirmationType={'warning'}>
        Your lunch was sent at {NUtils.date.formatTime(data.sendOutDate)} on{' '}
        {NUtils.date.formatDate(data.sendOutDate)} and is now frozen.
      </UI.Banner.NotificationWrapper>
    );
  }

  if (data.status === 4) {
    return (
      <UI.Banner.NotificationWrapper confirmationType={'warning'}>
        This is a template lunch
      </UI.Banner.NotificationWrapper>
    );
  }

  /*
p.alert.alert-info(ng-show='value.status == 1')
    i.fa.fa-info-circle
    |  Your lunch is ready and will be submitted at {{value.date.sendOut | date:'H:mm'}} on {{value.date.sendOut | date}}.

  p.alert.alert-warning(ng-show='value.status == 2')
    i.fa.fa-warning
    |  Initial groups have been assigned, but emails have not yet been sent out.


  p.alert.alert-warning(ng-show='value.status == 3')
      i.fa.fa-warning
      |  Your lunch was sent at {{value.date.sendOut | date:'H:mm'}} on {{value.date.sendOut | date}} and is now frozen.

  p.alert.alert-warning(ng-show='value.status == 4')
      i.fa.fa-warning
      |  This is a template lunch*/

  return null;
};

export default Status;
