import React, { useState } from 'react';

import { Id } from '@nexys/material-components/dist/common/type';
import { UI, Utils } from '@nexys/material-components';
import * as Data from 'interface/lunch/data';
import CopyForm from './form';

const useStyles = Utils.makeStyles(theme => ({
  buttonContainer: {
    display: 'inline-block',
    margin: theme.spacing(0, 2, 2, 0)
  }
}));

const {
  Buttons: { Button },
  Loader
} = UI;

interface Props {
  loginId: Id;
  lunchId: Id;
}

const CopyLunch = ({ loginId, lunchId }: Props): JSX.Element => {
  const [screen, setScreen] = useState<'default' | 'copy'>('default');
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = Utils.useSnackbar();
  const classes = useStyles();

  const handleCopyClick = () => {
    setScreen('copy');
  };

  const handleTemplateClick = () => {
    setLoading(true);

    Data.setAsTemplate(lunchId)
      .then(r => {
        Utils.confirmMessage(
          enqueueSnackbar,
          'Lunch set as template successfully',
          'success'
        );
      })
      .catch(err => {
        Utils.confirmMessage(enqueueSnackbar, err, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResetClick = () => {
    setLoading(true);

    Data.reset(lunchId)
      .then(r => {
        Utils.confirmMessage(
          enqueueSnackbar,
          'Lunch reset successful',
          'success'
        );
      })
      .catch(err => {
        Utils.confirmMessage(enqueueSnackbar, err, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancelCopy = () => setScreen('default');

  return (
    <>
      {screen === 'default' && (
        <>
          <div className={classes.buttonContainer}>
            <Button
              label="Copy lunch"
              onClick={handleCopyClick}
              disabled={loading}
              primary
            />
          </div>

          <div className={classes.buttonContainer}>
            <Button
              label="Set as template"
              onClick={handleTemplateClick}
              disabled={loading}
            />
          </div>

          <div className={classes.buttonContainer}>
            <Button
              label="Reset (set as active)"
              onClick={handleResetClick}
              disabled={loading}
            />
          </div>
        </>
      )}

      {screen === 'copy' && (
        <>
          <CopyForm loginId={loginId} lunchId={lunchId} />

          <Button label="Cancel" onClick={handleCancelCopy} />
        </>
      )}

      {loading && <Loader />}
    </>
  );
};

export default CopyLunch;
