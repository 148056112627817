import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { Data as D, UI, Utils } from '@nexys/material-components';
import * as T from 'interface/lunch/type';
import * as Data from 'interface/lunch/data';
import * as SendEmailData from 'interface/lunch/send-email';
import FormUI from './form-ui';
import EmailLogs from './email-logs';

const useStyles = Utils.makeStyles(theme => ({
  divider: {
    margin: theme.spacing(4, 0)
  }
}));

const {
  Buttons: { Back, Button },
  Cell,
  CellLayout,
  Layout,
  Loader
} = UI;

interface Props {
  data: T.Lunch;
}

const SendEmails = ({ data }: Props): JSX.Element => {
  const history = useHistory();
  const { enqueueSnackbar } = Utils.useSnackbar();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleSendClick = () => {
    setLoading(true);
    SendEmailData.sendMails(data)
      .then(r => {
        Utils.confirmMessage(
          enqueueSnackbar,
          'Emails were sent successfully',
          'success'
        );
      })
      .catch(err => {
        Utils.confirmMessage(
          enqueueSnackbar,
          'Error while sending emails',
          'error'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Layout.Title title="Send emails" type="pageTitle" />

      <Typography>Here you can send emails to lunch participants</Typography>

      <D.Form<T.Lunch> UI={FormUI} data={data} promise={SendEmailData.update} />

      <Divider className={classes.divider} />

      <CellLayout>
        <Cell title="Send Email">
          <Button
            label="Send Email Now"
            onClick={handleSendClick}
            primary
            disabled={loading}
          />
          {loading && <Loader />}
        </Cell>
      </CellLayout>

      <Divider className={classes.divider} />

      <EmailLogs lunchId={data.id} />

      <Back onClick={history.goBack} />
    </>
  );
};

const SendEmailsLoader = (): JSX.Element => {
  const { lunchId } = useParams<{ lunchId: string }>();

  return (
    <D.Load.WithLoader<T.Lunch>
      dataLoad={Data.detail(Number(lunchId))}
      Component={SendEmails}
    />
  );
};

export default SendEmailsLoader;
