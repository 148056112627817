import { Stateful } from '@nexys/material-components';

import { withBackend } from 'config';
import * as T from './type';

export const update = (data: Partial<T.Lunch>): Promise<boolean> => {
  if (!withBackend) {
    return Promise.resolve(true);
  }

  const dataForBackend = {
    body: data.sendEmailBody,
    html: data.sendEmailHtml,
    isHtml: data.sendEmailIsHtml,
    title: data.sendEmailTitle
  };

  try {
    return Stateful.Request.post(
      `/api/lunch/updateSendEmail?id=${data.id!}`,
      dataForBackend
    );
  } catch (err) {
    throw Error(err);
  }
};

// POST /lunch/sendEmail(id: Long)
export const sendMails = (data: T.Lunch): Promise<boolean> => {
  if (!withBackend) {
    return Promise.resolve(true);
  }

  const dataForBackend = {
    body: data.sendEmailBody,
    html: data.sendEmailHtml,
    isHtml: data.sendEmailIsHtml,
    title: data.sendEmailTitle
  };

  try {
    return Stateful.Request.post(
      `/api/lunch/sendEmail?id=${data.id}`,
      dataForBackend
    );
  } catch (err) {
    throw Error(err);
  }
};
