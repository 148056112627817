import NexysUtils from '@nexys/utils';

import * as CT from 'interface/crud/type';
import * as Data from './data';
import * as T from './type';

export const postProcessing = (emailLog: CT.EmailLog): T.EmailLog => {
  const statusId = Data.statusesMap.get(emailLog.statusId) || {
    id: -1,
    name: 'Unknown'
  };

  return {
    ...emailLog,
    dateAdded: new Date(emailLog.dateAdded),
    dateSend: new Date(emailLog.dateSend),
    dateSendTime: NexysUtils.date.formatTime(new Date(emailLog.dateSend)),
    statusId
  };
};
