import React from 'react';
import Button from '@nexys/material-components/dist/ui/button';

interface Links {
  optOutLink: string;
  optOutForeverLink: string;
}

const host = 'https://app.lunch-lottery.com';

const getLinks = async (userId: number, lunchId: number): Promise<Links> => {
  const r = await fetch('/api/lunch/user/optout/link', {
    method: 'POST',
    body: JSON.stringify({ userId, lunchId }),
    headers: { 'content-type': 'application/json' }
  });

  return r.json();
};

const OptOutLinks = ({
  lunchId,
  userId
}: {
  lunchId: number;
  userId: number;
}) => {
  const [links, setLinks] = React.useState<Links | null>(null);

  const onClick = async () => {
    const links = await getLinks(userId, lunchId);

    setLinks(links);
  };

  if (links) {
    return (
      <ul>
        <LinkUnit link={links.optOutLink} />
        <LinkUnit link={links.optOutForeverLink} />
      </ul>
    );
  }

  return <Button label={'Get opt out link'} onClick={onClick} />;
};

const LinkUnit = ({ link }: { link: string }) => (
  <li>
    <a target={'_blank'} rel="noopener noreferrer" href={host + link}>
      {link}
    </a>
  </li>
);

export default OptOutLinks;
