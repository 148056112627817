import { Id, Uuid } from '@nexys/material-components/dist/common/type';
import { Stateful } from '@nexys/material-components';

import { withBackend } from 'config';
import * as CT from 'interface/crud/type';
import * as T from './type';
import * as MockData from './mock-data';

const postProcessing = (participant: CT.Participant): T.Participant => ({
  ...participant,
  lunch: {
    ...participant.lunch,
    date_added: new Date(participant.lunch.date_added)
  },
  groupNumber: participant.lunch.group_idx
});

export const list = async (lunchId: Id): Promise<T.Participant[]> => {
  const r: any[] = await Stateful.Request.get(
    `/api/lunch/${lunchId}/participants`
  );

  return r.map(x => postProcessing(x));
};

export const listDomainsByLunch = async (
  lunchId: number
): Promise<{ domain: string; count: number }[]> => {
  const response = await fetch('/api/lunch/emailDomains?lunchId=' + lunchId, {
    headers: { 'content-type': 'application/json' },
    method: 'GET'
  });

  return response.json();
};

export const insert = (
  data: Partial<T.Participant>
): Promise<{ id: Id | Uuid }> => {
  if (!withBackend) {
    console.log('insert', data);
    return Promise.resolve({ id: 1 });
  }

  return Promise.resolve({ id: 1 });
};

export const detail = async (
  id: Id | Uuid,
  lunchId: Id
): Promise<T.Participant> => {
  if (!withBackend) {
    console.log('detail', id);
    const item = MockData.participants.find(d => d.id === id);
    return Promise.resolve(postProcessing(item || MockData.participants[0]));
  }

  const participants = await list(lunchId);
  const item = participants.filter(p => p.id === Number(id))[0];

  return item;
};

export const update = async (
  data: Partial<T.Participant>
): Promise<boolean> => {
  if (!withBackend) {
    console.log('update', data);
    return Promise.resolve(true);
  }

  try {
    const re = await Stateful.Request.get(
      `/api/lunch/people/update?groupIdx=${data.groupNumber}&userId=${
        data.lunch!.user_id
      }&lunchId=${data.lunch!.lunch_id}`
    );

    if (re.status && re.status === true) {
      return true;
    }

    throw Error('Error while trying to update');
  } catch (err) {
    throw Error(err as any);
  }
};

export const deleteById = async (
  participantId: Id | Uuid,
  lunchId: Id,
  userId: Id
): Promise<boolean> => {
  if (!withBackend) {
    console.log('deleteById', { participantId, lunchId, userId });
    return Promise.resolve(true);
  }

  console.log('deletebyid', { participantId, lunchId, userId });

  const re = await Stateful.Request.get(
    `/api/lunch/people/delete?lunchId=${lunchId}&userId=${participantId}&loginId=${userId}`
  );

  if (re.status && re.status === true) {
    return true;
  }

  throw Error('Error while trying to update');
};

export const importParticipants = async (
  lunchId: Id,
  csvContent: string
): Promise<any> => {
  if (!withBackend) {
    console.log('import', csvContent);
    return Promise.resolve(true);
  }

  try {
    await fetch(`/api/lunch/user/import?lunchId=${lunchId}`, {
      credentials: 'same-origin',
      headers: { 'content-type': 'text/plain;charset=UTF-8' },
      body: csvContent,
      method: 'POST'
    });

    return true;
  } catch (err) {
    throw Error(err as any);
  }
};
