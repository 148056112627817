import React from 'react';
import Typography from '@material-ui/core/Typography';

import { UI, Utils } from '@nexys/material-components';

const useStyles = Utils.makeStyles(theme => ({
  tagContainer: {
    marginBottom: theme.spacing(1)
  }
}));

const {
  Buttons: { Button }
} = UI;

export interface Tag {
  label: string;
  name: string;
}

interface Props {
  content: string;
  inputId: string;
  onInsert: (newContent: string) => void;
  tags: Tag[];
  description?: string;
}

const defaultDescription = 'Insert tags into your message:';

const Tags = ({
  content,
  inputId,
  onInsert,
  tags,
  description = defaultDescription
}: Props): JSX.Element | null => {
  const classes = useStyles();

  if (!tags.length) return null;

  const handleInsertTag = (fieldName: string) => {
    const textarea = document.getElementById(inputId) as HTMLTextAreaElement;
    const caretPosition = textarea.selectionStart;

    const newContent = [
      content.substr(0, caretPosition),
      `{{${fieldName}}}`,
      content.substr(caretPosition)
    ];

    onInsert(newContent.join(''));
  };

  return (
    <>
      {description !== '' && <Typography>{description}</Typography>}

      {tags.map(tag => (
        <div key={`tag-${tag.name}`} className={classes.tagContainer}>
          <Button
            label={tag.label}
            primary
            onClick={() => handleInsertTag(tag.name)}
          />
        </div>
      ))}
    </>
  );
};

export default Tags;
