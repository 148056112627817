import React from 'react';

import { ListDef } from '@nexys/material-components/dist/ui/business/list';
import { Id } from '@nexys/material-components/dist/common/type';
import { Data as D, UI, Utils } from '@nexys/material-components';
import * as Tabular from '@nexys/tabular';
import * as T from 'interface/optoutfrvr/type';
import * as Data from 'interface/optoutfrvr/data';

const {
  Buttons: { Button },
  Business
} = UI;

const UserLoader = ({ loginId }: { loginId: Id }): JSX.Element => {
  const dataDef: ListDef<T.User>[] = [
    {
      name: 'email',
      label: 'Email'
    },
    {
      name: 'firstName',
      label: 'First Name'
    },
    {
      name: 'lastName',
      label: 'Last Name'
    },
    {
      name: 'email',
      render: x => (
        <UI.Buttons.Button
          onClick={() => Data.deleteByEmail(loginId, x.email)}
          label={'Delete'}
        />
      )
    }
  ];

  const MyList = ({ data }: { data: T.User[] }): JSX.Element => {
    const handleExport = async () => {
      // format output

      if (data.length < 1) {
        throw Error('no data');
      }

      const headers = ['Email', 'First Name', 'Last Name'];
      const rows = data.map(d => [d.email, d.firstName, d.lastName]);

      const content = [headers, ...rows];

      const worksheet = 'Opt-Out Forever List';

      const tablularContent: { [k: string]: any } = { content };

      const x = await Tabular.TabularXlsx.toXlsx(tablularContent, worksheet);

      Utils.saveByteArray(
        'optoutfrvr.xlsx',
        x,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    };

    return (
      <>
        <div>
          <Button label="Export" onClick={handleExport} />
        </div>
        <Business.List data={data} def={dataDef} config={{ search: true }} />
      </>
    );
  };

  return (
    <D.Load.WithLoader<T.User[]>
      dataLoad={Data.list(loginId)}
      Component={MyList}
    />
  );
};

export default UserLoader;
