import React from 'react';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

import { Id } from '@nexys/material-components/dist/common/type';
import * as CrudT from '@nexys/material-components/dist/crud-simple/type';
import { CrudSimple, Data as D, Utils } from '@nexys/material-components';
import * as T from 'interface/feature/type';
import * as Data from 'interface/feature/data';
import * as Links from 'common/link';
import extras from './extras';
import SmartMatch from './smart-match';

const useStyles = Utils.makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2)
  }
}));

// data interface (CRUD)
const DataInterface = (lunchId: Id): CrudT.DataInterface<T.Feature> => ({
  list: () => Data.list(lunchId),
  detail: id => Data.detail(id, lunchId),
  insert: data => Data.insert(data, lunchId),
  update: Data.update,
  deleteById: id => Data.deleteById(id, lunchId)
});

// data defintiion/ config
const dataDef = (userId: Id, lunchId: Id): CrudT.DataDef<T.Feature> => ({
  urlPrefix: Links.App.getLunchDetail(userId, lunchId),
  fields: [
    { name: 'id', label: 'Id', type: 'number' },
    { name: 'name', label: 'Name', type: 'string', filter: true },
    {
      name: 'typeId',
      label: 'Type',
      type: 'select',
      options: Data.typeOptions,
      render: x => x.typeId.name
    },
    {
      name: 'options',
      label: 'Options',
      type: 'string',
      render: x => (
        <>
          {x.options.map(o => (
            <Chip
              key={`options-chip-${o.id}`}
              label={
                <>
                  {o.name} <small>({o.id})</small>
                </>
              }
              size="small"
              style={{ margin: '0 5px 5px 0' }}
              component="span"
            />
          ))}
        </>
      ),
      hide: { add: true, detail: true }
    }
  ],
  listConfig: { search: true },
  width: { xs: 12, sm: 12, md: 12, lg: 12 },
  detailUrlPrefix: 'feature'
});

interface Props {
  lunchId: Id;
  userId: Id;
}

export default ({ userId, lunchId }: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <D.Load.WithLoader<T.Feature[]>
          dataLoad={Data.list(lunchId)}
          Component={SmartMatch}
        />
      </div>

      <Typography className={classes.container}>
        This tab manages the different features that can be associated with
        every lunch participant. Note that smart matching will be automatically
        enabled if at least one feature is added. To disable smart matching
        simply delete all features.
      </Typography>

      <CrudSimple.Generic.App
        dataDef={dataDef(userId, lunchId)}
        Data={DataInterface(lunchId)}
        extra={extras(userId, lunchId)}
      />
    </>
  );
};
