import { Id, OptionSet } from '@nexys/material-components/dist/common/type';
import { Stateful } from '@nexys/material-components';

import { withBackend } from 'config';
import * as CT from 'interface/crud/type';
import * as LunchUtils from 'interface/lunch/utils';
import * as T from './type';
import * as U from './utils';
import * as MockData from './mock-data';

export const statusesMap: Map<Id, OptionSet> = new Map([
  [T.Status.pending, { id: T.Status.pending, name: 'Pending' }],
  [T.Status.sent, { id: T.Status.sent, name: 'Sent' }]
]);

export const list = async (lunchId: Id): Promise<T.EmailLog[]> => {
  if (!withBackend) {
    return Promise.resolve(MockData.data.map(d => U.postProcessing(d)));
  }

  try {
    const re: CT.EmailLog[] = await Stateful.Request.get(
      `/api/lunch/emailLog/list?lunchId=${lunchId}`
    );

    return re.map(l => U.postProcessing(l));
  } catch (err) {
    throw Error(err);
  }
};

export const insert = async (
  data: Partial<T.EmailLog>,
  lunchId: Id
): Promise<{ id: Id }> => {
  if (!withBackend) {
    return Promise.resolve({ id: 1 });
  }

  const dataForBackend = {
    lunchId,
    dateSend: LunchUtils.toDateIso(data.dateSendTime, data.dateSend)
  };

  try {
    const re = await Stateful.Request.post(
      `/api/lunch/emailLog/insert`,
      dataForBackend
    );

    if (re.id) {
      return re;
    }

    throw Error('Error while trying to insert email log');
  } catch (err) {
    throw Error(err);
  }
};

export const deleteById = (id: Id): Promise<boolean> => {
  if (!withBackend) {
    return Promise.resolve(true);
  }

  try {
    return Stateful.Request.get(`/api/lunch/emailLog/delete?lunchId=${id}`);
  } catch (err) {
    throw Error(err);
  }
};
