import React from 'react';

import { UI } from '@nexys/material-components';
import { Id } from '@nexys/material-components/dist/common/type';
import * as Data from 'interface/lunch/participant';
import { Participant } from 'interface/lunch/type';
import Chart from './chart';

const NetworkWrap = ({ data }: { data: Participant[] }) => {
  return <Chart participants={data} />;
};

const NetworkWithLoader = ({ lunchId }: { lunchId: Id }) => {
  const [data, setData] = React.useState<Participant[] | undefined>(undefined);
  const onClick = async () => {
    // fetch data
    const data = await Data.list(lunchId);
    // format output

    if (data.length < 1) {
      throw Error('no data');
    }

    setData(data);
  };

  if (data) {
    return <NetworkWrap data={data} />;
  }

  return <UI.Buttons.Button label={'Generate Network'} onClick={onClick} />;
};

export default NetworkWithLoader;
