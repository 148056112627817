import React from 'react';
import { listDomainsByLunch } from 'interface/lunch/participant';

export default ({ lunchId }: { lunchId: number }) => {
  const [data, setData] = React.useState<
    { domain: string; count: number }[] | null
  >(null);

  React.useEffect(() => {
    listDomainsByLunch(lunchId)
      .then(setData)
      .catch(error => {
        // Handle the error, e.g., set an error state and display it
        console.error('Error fetching data:', error);
      });
  }, [lunchId]);

  if (data === null) {
    return <p>Loading</p>;
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Domain</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => {
          return (
            <tr key={i}>
              <td>{row.domain}</td>
              <td>{row.count}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
