import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { Data as D, UI } from '@nexys/material-components';
import * as T from 'interface/lunch/type';
import * as Data from 'interface/lunch/data';
import * as OptionsData from 'interface/lunch/options';
import FormUI from './form-ui';

const {
  Buttons: { Back },
  Layout
} = UI;

interface Props {
  data: T.Lunch;
}

const Settings = ({ data }: Props): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <Layout.Title title="Email Template" type="pageTitle" />

      <Typography>
        Edit the email message which will be sent to every participant shortly
        before the lunch takes place.
      </Typography>

      <D.Form<T.Lunch> UI={FormUI} data={data} promise={OptionsData.update} />

      <Back onClick={history.goBack} />
    </>
  );
};

const SettingsLoader = (): JSX.Element => {
  const { lunchId } = useParams<{ lunchId: string }>();

  return (
    <D.Load.WithLoader<T.Lunch>
      dataLoad={Data.detail(Number(lunchId))}
      Component={Settings}
    />
  );
};

export default SettingsLoader;
