import { Id, Uuid } from '@nexys/material-components/dist/common/type';
import { Stateful } from '@nexys/material-components';

import { withBackend } from 'config';
import * as T from './type';
import * as MockData from './mock-data';

export const list = (): Promise<T.User[]> => {
  if (!withBackend) {
    console.log('list');
    return Promise.resolve(MockData.list);
  }

  return Stateful.Request.get('/api/user/list');
};

export const insert = (
  data: Omit<T.User, 'id'>
): Promise<{ id: Id | Uuid }> => {
  if (!withBackend) {
    console.log('insert', data);
    return Promise.resolve({ id: 1 });
  }

  // Structure for the backend
  const dataToInsert: T.UserInsert = {
    user: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email
    },
    password: {
      password1: data.password1!,
      password2: data.password2!
    }
  };

  return Stateful.Request.post(`/api/user/insert`, dataToInsert);
};

export const detail = (id: Id | Uuid): Promise<T.User> => {
  if (!withBackend) {
    console.log('detail', id);
    return Promise.resolve(MockData.detail);
  }

  return Stateful.Request.get(`/api/user/detail?id=${id}`);
};

export const update = (data: Partial<T.User>): Promise<boolean> => {
  if (!withBackend) {
    console.log('update', data);
    return Promise.resolve(true);
  }

  return Stateful.Request.post(`/api/user/update?id=${(data as any).id}`, data);
};

export const deleteById = (id: Id | Uuid): Promise<boolean> => {
  if (!withBackend) {
    console.log('deleteById', id);
    return Promise.resolve(true);
  }

  return Stateful.Request.get(`/api/user/delete?id=${id}`);
};
