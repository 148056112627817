import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { FormUIProps } from '@nexys/material-components/dist/hoc/type';
import { UI, Utils } from '@nexys/material-components';
import * as T from 'interface/lunch/type';
import * as Data from 'interface/lunch/data';
import Tags, { Tag } from './tags';
import { timezoneDefault } from 'interface/lunch/utils';

const useStyles = Utils.makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  bodyContainer: {
    marginTop: theme.spacing(1)
  },
  title: {
    marginTop: theme.spacing(4)
  },
  intro: {
    marginBottom: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(6, 0)
  }
}));

const {
  Banner,
  CellLayout,
  Form: { FormLayout, Wrapper, Input, Switch, Select, Datepicker, Textarea },
  Layout
} = UI;

const tags: Tag[] = [
  {
    label: 'Name',
    name: 'name'
  },
  {
    label: 'Meeting point',
    name: 'meeting_point'
  },
  {
    label: 'Date',
    name: 'date'
  },
  {
    label: 'Group number',
    name: 'number'
  },
  {
    label: 'Group',
    name: 'group'
  },
  {
    label: 'Group with phone',
    name: 'group_phone'
  },
  {
    label: 'Opt-Out forever',
    name: 'optOutForever'
  }
];

const FormUI = (props: FormUIProps<T.Lunch>): JSX.Element => {
  const { data, onChange, onSubmit, errors } = props;
  const classes = useStyles();

  const htmlOrBody = (): 'settingsHtml' | 'settingsBody' =>
    data.settingsIsHtml ? 'settingsHtml' : 'settingsBody';

  const handleInsertTag = (newContent: string) => {
    onChange({ name: htmlOrBody(), value: newContent });
  };

  return (
    <FormLayout onSubmit={onSubmit}>
      <CellLayout spacing={2} className={classes.container}>
        <Grid item xs={9}>
          <Wrapper name="settingsTitle" label="Email title" errors={errors}>
            <Input name="settingsTitle" onChange={onChange} data={data} />
          </Wrapper>

          <Wrapper
            name="settingsIsHtml"
            label="Is HTML email?"
            errors={errors}
            type="switch"
          >
            <Switch name="settingsIsHtml" onChange={onChange} data={data} />
          </Wrapper>

          <Banner.NotificationWrapper confirmationType="warning">
            You are currently editing the{' '}
            <strong>{!data.settingsIsHtml && 'non'} HTML</strong> version of the
            email.
          </Banner.NotificationWrapper>

          <div className={classes.bodyContainer}>
            {data.settingsIsHtml ? (
              <Wrapper
                name="settingsHtml"
                label="HTML Email body"
                errors={errors}
              >
                <Textarea
                  name="settingsHtml"
                  onChange={onChange}
                  data={data}
                  rows={20}
                />
              </Wrapper>
            ) : (
              <Wrapper name="settingsBody" label="Email body" errors={errors}>
                <Textarea
                  name="settingsBody"
                  onChange={onChange}
                  data={data}
                  rows={20}
                />
              </Wrapper>
            )}
          </div>
        </Grid>

        <Grid item xs={3}>
          <Tags
            content={data[htmlOrBody()] || ''}
            inputId={htmlOrBody()}
            onInsert={handleInsertTag}
            tags={tags}
          />
        </Grid>
      </CellLayout>

      <Divider className={classes.divider} />

      <Layout.Title
        title="Date and time of closing and send out"
        type="groupTitle"
        className={classes.title}
      />
      <p>
        <small>
          The timezone is <code>{timezoneDefault}</code>
        </small>
      </p>
      <CellLayout spacing={10}>
        <Grid xs={6} item>
          <Typography className={classes.intro}>
            Date and time of registration closing
          </Typography>

          <Wrapper
            name="closeDate"
            label="Close Date"
            errors={errors}
            type="date"
          >
            <Datepicker
              name="closeDate"
              data={data}
              onChange={onChange}
              shouldReturnDateObj
            />
          </Wrapper>

          <Wrapper name="closeTime" label="Close Time" errors={errors}>
            <Input name="closeTime" data={data} onChange={onChange} />
          </Wrapper>
        </Grid>

        <Grid xs={6} item>
          <Typography className={classes.intro}>
            Date and time of email send out
          </Typography>

          <Wrapper
            name="sendOutDate"
            label="Send Out Date"
            errors={errors}
            type="date"
          >
            <Datepicker
              name="sendOutDate"
              data={data}
              onChange={onChange}
              shouldReturnDateObj
            />
          </Wrapper>

          <Wrapper name="sendOutTime" label="Send Out Time" errors={errors}>
            <Input name="sendOutTime" data={data} onChange={onChange} />
          </Wrapper>

          <Wrapper name="lang" label="Language" errors={errors}>
            <Select
              name="lang"
              data={data}
              options={Data.languageOptions}
              onChange={onChange}
            />
          </Wrapper>
        </Grid>
      </CellLayout>
    </FormLayout>
  );
};

export default FormUI;
