import { OptionSet } from '@nexys/material-components/dist/common/type';

import * as CT from 'interface/crud/type';

export type EmailLog = Omit<
  CT.EmailLog,
  'dateAdded' | 'dateSend' | 'statusId'
> & {
  dateAdded: Date;
  dateSend: Date;
  dateSendTime: string;
  statusId: OptionSet;
};

export enum Status {
  pending = 1,
  sent = 2
}
