import React from 'react';

import * as T from 'interface/lunch/type';
import { Chip } from '@material-ui/core';

const getColor = (s: T.LunchStatus) => {
  if (s === T.LunchStatus.pending) {
    return 'primary';
  }

  if (s === T.LunchStatus.template) {
    return undefined;
  }

  return 'secondary';
};

export const Status = ({ statusId }: { statusId: T.LunchStatus }) => {
  const label = T.LunchStatus[statusId];
  const color = getColor(statusId);
  return <Chip color={color} label={label} />;
};

export const IdChip = ({ id }: { id: number }) => {
  return <Chip color={undefined} label={id} />;
};
