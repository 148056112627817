import React from 'react';
import Typography from '@material-ui/core/Typography';

import { Id } from '@nexys/material-components/dist/common/type';
import { Data as D, UI, Utils } from '@nexys/material-components';
import * as Data from 'interface/report/data';

const useStyles = Utils.makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 0, 2, 0)
  }
}));

const { CopyText, Layout } = UI;

interface Props {
  data: string;
}

const Report = ({ data }: Props): JSX.Element => {
  return <CopyText text={data} />;
};

interface LoaderProps {
  loginId: Id;
}

const ReportLoader = ({ loginId }: LoaderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Layout.Title title="Report" type="groupTitle" />

      <Typography>Click on the link to copy it.</Typography>

      <D.Load.WithLoader<string>
        dataLoad={Data.getKey(loginId)}
        Component={Report}
      />
    </div>
  );
};

export default ReportLoader;
