import React from 'react';

import Graph from 'react-graph-vis';
import * as Color from './colors';
import * as T from './type';
import { Participant } from 'interface/lunch/type';

import * as U from './utils';

const App = ({
  participants,
  onClick
}: {
  participants: Participant[];
  onClick: (n: number[]) => void;
}) => {
  const [featureIdx, setFeatureIdx] = React.useState<number | undefined>();
  const features = U.getFeatures(participants);

  const getColor = (node: Participant) => {
    if (featureIdx) {
      const colorIdx = node.features[0].option_id;
      return U.featureToColor(colorIdx);
    }

    return Color.colorDefault;
  };

  const nodes: T.Node[] = participants.map(x => {
    const color = getColor(x);
    return {
      id: x.id,
      label: x.email,
      color
    };
  });

  const edges: T.Edge[] = [];
  //.concat(...reportsEdges, ...friendsEdges)
  //.map((x, i) => ({ ...x, id: i }));

  const graph = {
    nodes,
    edges
  };

  const options = {
    layout: {
      //hierarchical: true,
    },
    edges: {
      color: '#000000'
    },
    height: '900px'
  };

  const events = {
    select: (a: {
      edges: number[];
      events: any;
      pointer: any;
      nodes: number[];
    }) => onClick(a.nodes)
  };
  return (
    <>
      <ul style={{ listStyleType: 'none' }}>
        {Array.from(features.entries()).map(([k, v]) => (
          <li>
            <button onClick={() => setFeatureIdx(featureIdx ? undefined : k)}>
              {featureIdx ? 'disable' : 'show'} {v}{' '}
            </button>
          </li>
        ))}
      </ul>

      <Graph
        graph={graph}
        options={options}
        events={events}
        /*getNetwork={network => {
          //console.log(network);
          //  if you want access to vis.js network api you can set the state in a parent component using this property
        }}*/
      />
    </>
  );
};

export default ({ participants }: { participants: Participant[] }) => (
  <App
    participants={participants}
    onClick={ids => {
      const subParticpants = participants.filter(x => ids.includes(x.id));
      alert(JSON.stringify(subParticpants, null, 2));
    }}
  />
);
