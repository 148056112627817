import React from 'react';

import { UI } from '@nexys/material-components';
import * as T from 'interface/feature/type';

const { Banner } = UI;

interface Props {
  data: T.Feature[];
}

const SmartMatch = ({ data }: Props): JSX.Element => {
  if (data.length) {
    return (
      <Banner.NotificationWrapper confirmationType="success">
        Smart Matching <strong>enabled</strong>
      </Banner.NotificationWrapper>
    );
  }

  return (
    <Banner.NotificationWrapper confirmationType="warning">
      Smart Matching <strong>disabled</strong>
    </Banner.NotificationWrapper>
  );
};

export default SmartMatch;
