import React from 'react';

import { Id } from '@nexys/material-components/dist/common/type';
import * as CrudT from '@nexys/material-components/dist/crud-simple/type';
import { CrudSimple } from '@nexys/material-components'; //'../lib/crud-simple/generic/app';
import * as CT from 'interface/crud/type';
import * as Data from 'interface/option/data';
import * as Links from 'common/link';

// data interface (CRUD)
const DataInterface = (
  lunchId: Id,
  featureId: Id
): CrudT.DataInterface<CT.Option> => ({
  list: () => Data.list(lunchId, featureId),
  detail: id => Data.detail(id, lunchId, featureId),
  insert: data => Data.insert(data, lunchId, featureId),
  update: data => Data.update(data, lunchId, featureId),
  deleteById: id => Data.deleteById(id, lunchId)
});

// data defintiion/ config
const dataDef = (
  userId: Id,
  lunchId: Id,
  featureId: Id
): CrudT.DataDef<CT.Option> => ({
  urlPrefix: Links.App.getFeatureDetail(userId, lunchId, featureId),
  fields: [{ name: 'name', label: 'Name', type: 'string', filter: true }],
  listConfig: { search: true },
  width: { xs: 12, sm: 12, md: 12, lg: 12 },
  detailUrlPrefix: 'option'
});

interface Props {
  featureId: Id;
  lunchId: Id;
  userId: Id;
}

export default ({ featureId, lunchId, userId }: Props) => (
  <CrudSimple.Generic.App
    dataDef={dataDef(userId, lunchId, featureId)}
    Data={DataInterface(lunchId, featureId)}
  />
);
