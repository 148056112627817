import React from 'react';

import { UI } from '@nexys/material-components';
import { Id } from '@nexys/material-components/dist/common/type';
import * as Data from 'interface/lunch/participant';
import { Participant } from 'interface/lunch/type';
import { Dataset } from './type';
import BarUnit from './unit';
import { extractFeatures } from './utils';

const BarWrap = ({ data }: { data: Participant[] }) => {
  const formattedFeatures = extractFeatures(data);

  return (
    <ul style={{ listStyleType: 'none' }}>
      {Array.from(formattedFeatures).map(([label, v], i) => {
        const aValues: [string, number][] = Array.from(v).sort(
          (a, b) => b[1] - a[1]
        ); // sort so that most populous appear first
        const data: number[] = aValues.map(([_k, v]) => v);
        const labels: string[] = aValues.map(([k]) => k);

        const d: Dataset = {
          label,
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data
        };

        return (
          <li key={i}>
            <BarUnit title={label} labels={labels} datasets={[d]} />
          </li>
        );
      })}
    </ul>
  );
};

const BarWithLoader = ({ lunchId }: { lunchId: Id }) => {
  const [data, setData] = React.useState<Participant[] | undefined>(undefined);
  const onClick = async () => {
    // fetch data
    const data = await Data.list(lunchId);
    // format output

    if (data.length < 1) {
      throw Error('no data');
    }

    setData(data);
  };

  if (data) {
    return (
      <>
        <BarWrap data={data} />
      </>
    );
  }

  return <UI.Buttons.Button label={'Generate Charts'} onClick={onClick} />;
};

export default BarWithLoader;
