import React from 'react';

import { UI } from '@nexys/material-components';

const secret =
  'otpauth://totp/nexys@us3.admin.mailchimp.com?secret=VYRMVS27CZL4EPVX';

const QrTotp = () => {
  const [qr, setQr] = React.useState(false);

  if (qr === false) {
    return (
      <small>
        <UI.Buttons.Button
          onClick={() => setQr(true)}
          label={'See 2FA QrCode'}
        />
      </small>
    );
  }
  return (
    <small>
      <UI.Buttons.Button onClick={() => setQr(false)} label={'hide'} />
      <UI.QrCode value={secret} size={100} />
    </small>
  );
};

export default () => {
  return (
    <>
      <a href="https://us3.admin.mailchimp.com/transactional/">
        Link to Mandrill module
      </a>{' '}
      <QrTotp />
    </>
  );
};
