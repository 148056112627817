import React from 'react';

import { UI } from '@nexys/material-components';

import Overview from './overview';
import Groups from './group';
import * as Links from 'common/link';

export default () => (
  <>
    <UI.Tabs
      tabs={[
        { label: 'Overview', link: '', Component: <Overview /> },
        { label: 'Groups', link: '/groups', Component: <Groups /> }
      ]}
      prefix={Links.App.lunchMain}
    />
  </>
);
