import React from 'react';
import { Redirect } from 'react-router-dom';

import { Data } from '@nexys/material-components';
import * as D from 'interface/lunch/data';
import * as T from 'interface/lunch/type';
import List from './list';

const Overview = () => {
  return (
    <>
      <h1>Lunch Overview</h1>
      <p>
        This page lists all lunch starting from{' '}
        {new Date(new Date().getTime() - 1000 * 3600 * 24 * 3).toLocaleString()}{' '}
        (today - 3 days)
      </p>
      <Data.Load.WithLoader<T.LunchOverview[]>
        Component={List}
        dataLoad={D.overview()}
        NoDataComponent={() => <Redirect to={'/login'} />}
      />
    </>
  );
};

export default Overview;
