import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { UI, Utils } from '@nexys/material-components';
import { NginxSample, DebugCmd } from './nginx';
import Cheatsheet from './cheatsheet';
import Mailchimp from './mailchimp';
import GotoLunch from './go-to-lunch';

const useStyles = Utils.makeStyles(theme => ({
  buttonContainer: {
    margin: theme.spacing(0, 0, 2, 0),
    textAlign: 'right'
  }
}));

const { Layout } = UI;

const Resources = () => {
  const whatsappLink = 'https://wa.me/+41797626865';
  return (
    <ul>
      <li>
        Mailchimp / Mandrill: <Mailchimp />
      </li>
      <li>
        Issue tracking:{' '}
        <a href="https://docs.google.com/spreadsheets/d/1pTb2jM1bnPTbn9jrsPZHCLsVEIiv2rYTsV5ggrgsV1g/edit#gid=0">
          Here
        </a>
      </li>
      <li>
        Lunch Lottery GFolder:{' '}
        <a href="https://drive.google.com/drive/folders/0B9SklmnZkMiPRk5pOVd3Sy15aFU">
          Here
        </a>
      </li>
      <li>
        Protocol{' '}
        <a href="https://drive.google.com/file/d/1MsZ0fL1-m-9tZmLFsmIsDv1A9JArS_As/view?usp=sharing">
          Link
        </a>
      </li>
      <li>
        Admin platform:{' '}
        <a href="https://app.digis.ch?instance=lunchlottery">Digis</a>
      </li>
      <li>
        Chat with support: <a href={whatsappLink}>Here</a>
      </li>
      <li>
        Architecture #1{' '}
        <a href="https://docs.google.com/drawings/d/1lc2AVC5PcmuQFgzLL3QQKYiVbaer-QpBLfBi3EajhkQ/edit">
          Here
        </a>
      </li>
      <li>
        Architecture #2{' '}
        <a href="https://docs.google.com/drawings/d/1D3MvZe4eFbPwa9QjCVBtBTnPtt17YYfL4qzVMhqBmYo/edit">
          Here
        </a>
      </li>
    </ul>
  );
};

export default () => {
  const [toggle, setToggle] = useState(false);
  const [showDeployConfig, setShowDeployConfig] = useState(false);

  const classes = useStyles();

  return (
    <Layout.Simple title="LunchLottery Admin">
      <h4>Resources</h4>
      <Resources />
      <h4>Go To Lunch</h4>
      <GotoLunch />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div className={classes.buttonContainer}>
            <Button
              onClick={() => setShowDeployConfig(!showDeployConfig)}
              startIcon={<HelpOutlineIcon />}
              variant="contained"
            >
              {showDeployConfig ? 'Hide Deploy Config' : 'Show Deploy Config'}
            </Button>
          </div>

          {showDeployConfig && (
            <>
              The variables <code>MatchsessionId</code> and{' '}
              <code>sessionKey</code>. If smart matching is enabled, some
              mapping is required too.
              <NginxSample />
              For the webflow code, generate the code in the lunch directly.
              <DebugCmd />
            </>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.buttonContainer}>
            <Button
              onClick={() => setToggle(!toggle)}
              startIcon={<HelpOutlineIcon />}
              variant="contained"
            >
              {toggle ? 'Hide Cheatsheet' : 'Show Cheatsheet'}
            </Button>
          </div>

          {toggle && <Cheatsheet />}
        </Grid>
      </Grid>
    </Layout.Simple>
  );
};
