import React from 'react';
import { Link } from 'react-router-dom';

import * as MUIList from '@nexys/mui-list';
import * as T from 'interface/lunch/type';
import * as Data from 'interface/lunch/data';
import { UI, Data as HData } from '@nexys/material-components';

import NUtils from '@nexys/utils';
import * as Links from 'common/link';
import { IconButton } from '@material-ui/core';
import { Search as GoIcon } from '@material-ui/icons';

const { Layout } = UI;

// data defintiion/ config
const dataDef: MUIList.Types.Definition<T.LunchGroup> = [
  { name: 'email', label: 'Email', filter: true },
  { name: 'n', label: 'Number of lunches' },
  {
    name: 'dates',
    label: 'Max Date',
    render: x => NUtils.date.formatDate(x.dates.date)
  },
  {
    name: 'dates',
    label: 'Max Send Out',
    render: x => NUtils.date.formatDate(x.dates.sendOut)
  },
  {
    name: 'dates',
    label: 'Max Closing',
    render: x => NUtils.date.formatDate(x.dates.close)
  },
  {
    name: 'email',
    label: '',
    render: x => (
      <IconButton
        component={Link}
        to={Links.App.lunch + '/' + encodeURIComponent(x.userId) + '/lunches'}
      >
        <GoIcon />
      </IconButton>
    )
  }
];

const List = ({ data }: { data: T.LunchGroup[] }) => (
  <MUIList.List def={dataDef} data={data} />
);

export default () => (
  <>
    <Layout.Title title="Lunch" type="pageTitle" />

    <HData.Load.WithLoader<T.LunchGroup[]>
      Component={({ data }) => <List data={data} />}
      dataLoad={Data.listGroup()}
    />
  </>
);
