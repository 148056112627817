import React from 'react';

import { Id } from '@nexys/material-components/dist/common/type';
import { Field } from '@nexys/material-components/dist/simple-ui/form-simple/type';
import { SimpleUI } from '@nexys/material-components';
import * as Data from 'interface/lunch/data';
import * as T from 'interface/lunch/type';

interface Props {
  loginId: Id;
  lunchId: Id;
}

const CopyForm = ({ loginId, lunchId }: Props): JSX.Element => {
  const fields: Field<T.CopyLunch>[] = [
    {
      label: 'Title',
      name: 'name',
      type: 'string'
    },
    {
      label: 'Date',
      name: 'date',
      type: 'date',
      inputProps: { shouldReturnDateObj: true }
    },
    {
      label: 'Time',
      name: 'time',
      type: 'string',
      inputProps: { placeholder: 'HH:mm (24-hour format)' }
    }
  ];

  return (
    <SimpleUI.FormSimple<T.CopyLunch>
      fields={fields}
      data={{} as any}
      promise={data => Data.copy(lunchId, loginId, data)}
      message={{
        success: 'Successfully copied lunch',
        error: 'Error while copying lunch'
      }}
    />
  );
};

export default CopyForm;
