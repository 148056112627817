import React from 'react';
import { UI } from '@nexys/material-components';
import * as Link from 'common/link';

import bgImage from '../login/login-bg.jpg';

export default () => (
  <UI.Layout.Login title={'Signup'} backgroundImage={bgImage} layout="left">
    <h2>Signup</h2>
    <p>here signup...</p>

    <p>
      <UI.Link to={Link.Public.login}>to login</UI.Link>
    </p>
  </UI.Layout.Login>
);
