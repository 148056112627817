import { Id } from '@nexys/material-components/dist/common/type';
import * as U from './utils';

export const list = async (loginId: Id): Promise<any> => {
  const a = await fetch(`/api/lunch/optoutfrvr?loginId=${loginId}`, {
    credentials: 'same-origin',
    headers: { 'content-type': 'text/plain;charset=UTF-8' },
    method: 'GET'
  }).then(x => x.text());

  return U.postProcessing(a);
};

export const deleteByEmail = async (loginId: Id, email: string) => {
  if (window.confirm('are you sure you would like to delete')) {
    const body = JSON.stringify({ loginId, email });
    const a = await fetch(`/api/lunch/optoutfrvr/delete`, {
      credentials: 'same-origin',
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body
    });
    const j = await a.json();

    return U.postProcessing(j);
  }
};
