import React from 'react';

import * as CrudT from '@nexys/material-components/dist/crud-simple/type';

import * as T from 'interface/lunch/type';

import Participants from '../../participants/index';
import Features from '../../features/index';
import { Id } from '@nexys/material-components/dist/common/type';

import Status from './status';
import Link from './link';
import CopyLunch from './copy-lunch';
import ListByDomain from './list-by-domain';

const extras = (userId: Id): CrudT.ExtraUnit<T.Lunch>[] => [
  {
    title: 'Status',
    Component: (_id, data) => (
      <>
        <Status data={data} />
        <br />
        <Link data={data} />
        <br />
        <ListByDomain lunchId={data.id} />
      </>
    )
  },
  {
    title: 'Features',
    Component: id => <Features userId={userId} lunchId={Number(id)} />
  },
  {
    title: 'Copy Lunch',
    Component: id => <CopyLunch loginId={userId} lunchId={Number(id)} />
  },
  {
    title: 'Participants of the lunch',
    Component: (id, lunch) => (
      <Participants
        statusId={lunch.status}
        lunchId={Number(id)}
        userId={userId}
      />
    ),
    width: { xs: 12, sm: 12, md: 12, lg: 12 }
  }
];

export default extras;
