import React from 'react';

import { FormUIProps } from '@nexys/material-components/dist/hoc/type';
import { UI } from '@nexys/material-components';
import * as T from 'interface/email-log/type';

const {
  Form: { FormLayout, Wrapper, Datepicker, Input }
} = UI;

const FormUI = (props: FormUIProps<T.EmailLog>): JSX.Element => {
  const { data, onChange, onSubmit, errors } = props;

  return (
    <FormLayout onSubmit={onSubmit} submitLabel="Schedule Send">
      <Wrapper name="dateSend" label="Date" errors={errors} type="date">
        <Datepicker
          name="dateSend"
          data={data}
          onChange={onChange}
          shouldReturnDateObj
        />
      </Wrapper>

      <Wrapper name="dateSendTime" label="Time" errors={errors}>
        <Input
          name="dateSendTime"
          data={data}
          onChange={onChange}
          placeholder="HH:mm (24-hour format)"
        />
      </Wrapper>
    </FormLayout>
  );
};

export default FormUI;
