import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Dataset } from './type';

const BarUnit = ({
  title,
  labels,
  datasets
}: {
  title: string;
  labels: string[];
  datasets: Dataset[];
}) => {
  const inputData = {
    labels,
    datasets
  };

  return (
    <div>
      <h2>{title}</h2>
      <Bar
        data={inputData}
        width={100}
        height={50}
        options={{
          maintainAspectRatio: false
        }}
      />
    </div>
  );
};

export default BarUnit;
