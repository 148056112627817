import React from 'react';
import { useParams } from 'react-router-dom';
import * as LunchService from 'interface/lunch/data';
import * as LunchFeatureService from 'interface/feature/data';
import { Data as D } from '@nexys/material-components';
import { Feature } from 'interface/feature/type';

const WebflowSample = async (lunchId: number): Promise<string> => {
  const r = await fetch('/webflow.html');
  const detail = await LunchService.detail(lunchId);
  const features = await LunchFeatureService.list(lunchId);
  return (await r.text())
    .replace('inputLunchId', String(lunchId))
    .replace('inputLunchKey', detail.key)
    .replace('// features', featuresToCode(features));
};

export const WebflowWLoader = ({ lunchId }: { lunchId: number }) => {
  return (
    <D.Load.WithLoader
      Component={({ data }) => (
        <pre
          onClick={() =>
            navigator.clipboard.writeText(data).then(x => alert('code copied'))
          }
        >
          {data}{' '}
        </pre>
      )}
      dataLoad={WebflowSample(lunchId)}
    />
  );
};

const featuresToCode = (f: Feature[]) => {
  const fString = f
    .map(feature => {
      return `\n      { "featureId": ${feature.id}, "valueId": Number(profile['${feature.name}']) }`;
    })
    .join(',');

  return "profile['features'] = [" + fString + '\n    ];';
};

export default () => {
  const {lunchId} = useParams<{ lunchId?: string }>()

  if (!lunchId) {
    throw Error('lunch id is expected as a url params')
  }

  return (
    <>
      <h2>Webflow code snippet</h2>
      <p>Copy text below, click on it to copy</p>
      <p><a href="https://docs.google.com/document/d/1OOGpbMJ2pZo7EFB1zn0dvb8-9JgCfPp39BJp58FYmvI/edit#" target={'_blank'} rel="noopener noreferrer">Extra information here</a></p>
      <WebflowWLoader lunchId={Number(lunchId)} />
    </>
  );
};
