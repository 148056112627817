import { Id, UOptionSet } from '@nexys/material-components/dist/common/type';
import * as CT from 'interface/crud/type';

export type Lunch = Omit<CT.Lunch, 'date' | 'lang'> & {
  date: Date;
  time: string;
  sendOutDate: Date;
  sendOutTime: string;
  closeDate: Date;
  closeTime: string;
  added: Date;
  isLunchExpired: boolean;
  settingsTitle: string;
  settingsBody: string;
  settingsHtml: string;
  settingsIsHtml: boolean;
  sendEmailTitle: string;
  sendEmailBody: string;
  sendEmailHtml: string;
  sendEmailIsHtml: boolean;
  lang: UOptionSet;
};

// this one is actually unused
export enum LunchType {
  normal = 1
}

export enum LunchStatus {
  pending = 1,
  closed = 2,
  sent = 3,
  template = 4
}

export interface LunchOverview {
  id: Id;
  name: string;
  dateClose: Date;
  dateSendOut: Date;
  typeId: LunchType;
  statusId: LunchStatus;
  user: { id: Id; email: string };
}

export interface LunchGroup {
  n: number;
  userId: number;
  email: string;
  dates: {
    date: Date;
    close: Date;
    sendOut: Date;
  };
}

export type Participant = Omit<CT.Participant, 'lunch'> & {
  lunch: Omit<CT.ParticipantLunch, 'date_added'> & {
    date_added: Date;
  };
  groupNumber?: number;
};

interface LunchDate {
  date: Date;
  sendOut: Date;
  close: Date;
  added: Date;
  isLunchExpired: boolean;
}

export interface Url {
  registration: string;
  unsubscribe: string;
  optOut: string;
}

export interface LunchList {
  id: Id;
  date: LunchDate;
  meeting_point: string;
  group_size: number;
  type_id: LunchType;
  name: string;
  user_id: number;
  key: string;
  lang: string;
  emailFrom: string;
  location_id: number;
  status: number;
  url: Url;
  nbParticipants: number;
}

export interface CopyLunch {
  name: string;
  date: Date;
  time: string;
}

// subset of https://stackoverflow.com/questions/38399465/how-to-get-list-of-all-timezones-in-javascript
export type Timezone =
  | 'Europe/Zurich'
  | 'Europe/London'
  | 'America/New_York'
  | 'America/Chicago'
  | 'America/Los_Angeles'
  | 'Australia/Sydney'
  | 'Asia/Tokyo'
  | 'Asia/Shanghai'
  | 'Asia/Singapore'
  | 'Asia/Hong_Kong'
  | 'Europe/Moscow'
  | 'Asia/Kolkata'
  | 'Asia/Dubai';
