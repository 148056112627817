import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import Layout from './layout';
import Dashboard from './dashboard';
import Lunch from './lunch';
import User from './user';
import authWrapper from '../common/auth/wrapper';

import * as Links from 'common/link';

const UnauthorizedPage = () => <p>This content is unauthorized</p>;

function App(props: RouteComponentProps) {
  return (
    <Layout>
      <Switch>
        <Route path={Links.App.dashboard} component={Dashboard} props={props} />
        <Route path={Links.App.lunch} component={Lunch} props={props} />
        <Route path={Links.App.user} component={User} props={props} />
        <Route
          path={Links.App.unauthorizedPage}
          component={authWrapper(
            UnauthorizedPage,
            'permissionThatDoesNotExist'
          )}
          props={props}
        />
        <Route
          path={Links.App.default}
          component={() => <Redirect to={Links.App.dashboard} />}
        />
      </Switch>
    </Layout>
  );
}

export default authWrapper(App, 'app');
