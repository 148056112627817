import React from 'react';

import * as NUtils from '@nexys/utils';
import { UI } from '@nexys/material-components';
import * as L from '@nexys/mui-list';
import * as T from 'interface/lunch/type';
import * as Links from 'common/link';
import { Search as SeeIcon } from '@material-ui/icons';
import { toDateTZ } from 'interface/lunch/utils';

import { Status, IdChip } from '../utils/ui';

const formatDate = (pdate: Date) => {
  const date = toDateTZ(String(pdate));
  return NUtils.date.formatDate(date) + ' ' + NUtils.date.formatTime(date);
};

const def: L.Types.Definition<T.LunchOverview> = [
  { name: 'id', label: 'Id', render: x => <IdChip id={x.id} /> },
  {
    name: 'name',
    label: 'Name',
    render: x => (
      <UI.Link to={Links.App.getLunchDetail(x.user.id, x.id)}>{x.name}</UI.Link>
    )
  },
  {
    name: 'dateClose',
    label: 'Date Close',
    render: x => formatDate(x.dateClose)
  },
  {
    name: 'dateSendOut',
    label: 'Date Send Out',
    render: x => formatDate(x.dateSendOut)
  },
  {
    name: 'user',
    label: 'Account Email',
    render: x => (
      <UI.Link to={Links.App.userDetail(x.user.id)}>{x.user.email}</UI.Link>
    )
  },
  {
    name: 'statusId',
    render: x => <Status statusId={x.statusId} />
  },
  {
    name: 'id',
    render: x => (
      <UI.Link to={Links.App.getLunchDetail(x.user.id, x.id)}>
        <SeeIcon />
      </UI.Link>
    )
  }
];

const List = ({ data }: { data: T.LunchOverview[] }) => (
  <L.List data={data} def={def} />
);

export default List;
