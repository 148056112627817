import { OptionSet } from '@nexys/material-components/dist/common/type';

import * as CT from 'interface/crud/type';

export type Feature = Omit<CT.Feature, 'typeId'> & {
  typeId: OptionSet;
};

export enum Type {
  maximize = 1,
  minimize = 2
}
