import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Id } from '@nexys/material-components/dist/common/type';
import { Data as D, UI, Utils } from '@nexys/material-components';
import * as T from 'interface/email-log/type';
import * as Data from 'interface/email-log/data';
import dataDef from './def';
import FormUI from './form-ui';

const useStyles = Utils.makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  }
}));

const { Business, CellLayout } = UI;

interface Props {
  data: T.EmailLog[];
  onDelete: () => void;
}

const EmailLogs = ({ data, onDelete }: Props): JSX.Element => {
  return <Business.List data={data} def={dataDef(onDelete)} />;
};

interface LoaderProps {
  lunchId: Id;
}

const EmailLogsLoader = ({ lunchId }: LoaderProps): JSX.Element => {
  const [reload, setReload] = useState(false);
  const classes = useStyles();

  const triggerReload = () => setReload(!reload);

  return (
    <CellLayout spacing={2} className={classes.container}>
      <Grid item xs={12} sm={6}>
        <Typography>
          Select a date to schedule the sending of above email to all lunch
          participants.
        </Typography>

        <D.Form<T.EmailLog>
          data={{}}
          UI={FormUI}
          promise={data => Data.insert(data, lunchId)}
          onUpdate={triggerReload}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>Scheduled emails log</Typography>

        <D.Load.WithLoader<T.EmailLog[], { onDelete: () => void }>
          Component={EmailLogs}
          dataLoad={Data.list(lunchId)}
          onDelete={triggerReload}
        />
      </Grid>
    </CellLayout>
  );
};

export default EmailLogsLoader;
