// repository of all links used in the application to avoid wrong refewrences

import { Id } from '@nexys/material-components/dist/common/type';

/*
attempt at having a less verbose solution: works but no autocomplete ...
const appArray = ['dashboard', 'crud', 'profile', 'unauthorizedPage'];

const AppMap: Map<string, string> = new Map(
  appArray.map(k => {
    return [k, '/app/' + k];
  })
);

export const App = Object.fromEntries(AppMap);
*/

const toRouteApp = (s: string) => '/app' + s;
const toRoutePublic = (s: string) => '' + s;

export const Public = {
  default: toRoutePublic('/'),
  signup: toRoutePublic('/signup'),
  unauthorized: toRoutePublic('/unauthorized'),
  login: toRoutePublic('/login'),
  logout: toRoutePublic('/logout')
};

export const App = {
  default: toRouteApp('/'),
  dashboard: toRouteApp('/dashboard'),
  profile: toRouteApp('/profile'),
  unauthorizedPage: toRouteApp('/unauthorizedPage'),
  lunch: toRouteApp('/lunch'),
  lunchMain: toRouteApp('/lunch/main'),
  lunchByUserPrefix: (userId: Id) => toRouteApp('/lunch/' + userId),
  lunchByUser: (userId: Id) => toRouteApp('/lunch/' + userId + '/lunches'),
  luncUserCreate: (userId: Id) =>
    toRouteApp('/lunch/' + userId + '/lunches/add'),
  getLunchDetail: (userId: Id, id: Id) =>
    toRouteApp(`/lunch/${userId}/lunches/${id}/detail`),
  getFeatureDetail: (userId: Id, lunchId: Id, featureId: Id) =>
    toRouteApp(
      `/lunch/${userId}/lunches/${lunchId}/detail/feature/${featureId}/detail`
    ),
  user: toRouteApp('/user'),
  userDetail: (id: Id) => toRouteApp('/user') + `/${id}/detail`
};
