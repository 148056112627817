import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import NexysUtils from '@nexys/utils';
import { ListDef } from '@nexys/material-components/dist/ui/business/list';
import { Data as D } from '@nexys/material-components';

import * as Data from 'interface/email-log/data';
import * as T from 'interface/email-log/type';

const def = (onDelete: () => void): ListDef<T.EmailLog>[] => [
  {
    name: 'dateAdded',
    label: 'Date Added',
    render: x =>
      `${NexysUtils.date.formatDate(x.dateAdded)} ${NexysUtils.date.formatTime(
        x.dateAdded
      )}`
  },
  {
    name: 'dateSend',
    label: 'Date Send',
    render: x =>
      `${NexysUtils.date.formatDate(x.dateSend)} ${NexysUtils.date.formatTime(
        x.dateSend
      )}`
  },
  {
    name: 'statusId',
    label: 'Status',
    render: x => x.statusId.name
  },
  {
    name: 'id',
    label: 'Unschedule Email',
    render: x => (
      <D.Delete.Delete
        promise={id => Data.deleteById(Number(id))}
        id={x.id}
        UI={props => (
          <IconButton color="secondary" onClick={props.onClick}>
            <DeleteIcon />
          </IconButton>
        )}
        onSuccess={onDelete}
      />
    )
  }
];

export default def;
