import { Stateful } from '@nexys/material-components';

import { withBackend } from 'config';
import * as T from './type';
import * as U from './utils';

export const update = async (data: Partial<T.Lunch>): Promise<boolean> => {
  if (!withBackend) {
    return Promise.resolve(true);
  }

  const dateClose = U.toDateIso(data.closeTime, data.closeDate);
  const dateSendOut = U.toDateIso(data.sendOutTime, data.sendOutDate);

  const dataForBackend = {
    dateClose,
    dateSendOut,
    email: {
      body: data.settingsBody,
      html: data.settingsHtml,
      isHtml: data.settingsIsHtml,
      title: data.settingsTitle
    },
    emailFrom: data.emailFrom,
    group_size: data.group_size,
    id: data.id,
    invitation_text: data.invitation_text,
    key: data.key,
    lang: data.lang?.uuid,
    location_id: data.location_id,
    meeting_point: data.meeting_point,
    name: data.name,
    nbParticipants: data.nbParticipants,
    sendEmail: data.sendEmail,
    status: data.status,
    type_id: data.type_id,
    url: data.url,
    user_id: data.user_id
  };

  try {
    const re = await Stateful.Request.post(
      `/api/lunch/updateOptions?id=${data.id!}`,
      dataForBackend
    );

    if (re.status && re.status === true) {
      return true;
    }

    throw Error('Error while trying to update');
  } catch (err) {
    throw Error(err);
  }
};
