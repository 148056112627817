import * as Color from './colors';
import { Participant } from 'interface/lunch/type';

export const featureToColor = (c?: number) => {
  if (c) {
    return Color.getColorByInt(c);
  }

  return Color.colorDefault;
};

export const getFeatures = (ps: Participant[]): Map<number, string> => {
  const features: Map<number, string> = new Map();

  ps.forEach(p => {
    p.features.forEach(f => {
      features.set(f.feature_id, f.name);
    });
  });

  return features;
};
