import {
  Id,
  OptionSet,
  Uuid
} from '@nexys/material-components/dist/common/type';
import { Stateful } from '@nexys/material-components';

import { withBackend } from 'config';
import * as CT from 'interface/crud/type';
import * as T from './type';
import * as U from './utils';

export const typeOptions: OptionSet[] = [
  {
    id: T.Type.maximize,
    name: 'Maximize diversity'
  },
  {
    id: T.Type.minimize,
    name: 'Minimize diversity'
  }
];

export const list = async (lunchId: Id): Promise<T.Feature[]> => {
  if (!withBackend) {
    console.log('list');
    return Promise.resolve([]);
  }

  try {
    const r: CT.Feature[] = await Stateful.Request.get(
      `/api/lunch/features/${lunchId}/list`
    );
    return r.map(d => U.postProcessing(d));
  } catch (err) {
    throw Error(err);
  }
};

export const insert = async (
  data: Partial<T.Feature>,
  lunchId: Id
): Promise<{ id: Id | Uuid }> => {
  if (!withBackend) {
    console.log('insert', data);
    return Promise.resolve({ id: 1 });
  }

  const dataForBackend = {
    name: data.name!,
    typeId: data.typeId!.id
  };

  try {
    return Stateful.Request.post(
      `/api/lunch/features/insert?id=${lunchId}`,
      dataForBackend
    );
  } catch (err) {
    throw Error(err);
  }
};

export const detail = async (
  id: Id | Uuid,
  lunchId: Id
): Promise<T.Feature> => {
  const features = await list(lunchId);

  const item = features.filter(f => f.id === Number(id))[0];
  return item;
};

export const update = (data: Partial<T.Feature>): Promise<boolean> => {
  if (!withBackend) {
    console.log('update', data);
    return Promise.resolve(true);
  }

  const dataForBackend = {
    id: data.id,
    lunch_id: data.lunch_id,
    name: data.name,
    options: data.options,
    typeId: data.typeId?.id
  };

  try {
    return Stateful.Request.post(
      `/api/lunch/features/update?id=${data.lunch_id}&featureId=${data.id}`,
      dataForBackend
    );
  } catch (err) {
    throw Error(err);
  }
};

export const deleteById = (id: Id | Uuid, lunchId: Id): Promise<boolean> => {
  if (!withBackend) {
    console.log('deleteById', id);
    return Promise.resolve(true);
  }

  try {
    return Stateful.Request.get(
      `/api/lunch/features/delete?featureId=${id}&lunchId=${lunchId}`
    );
  } catch (err) {
    throw Error(err);
  }
};
