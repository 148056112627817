import { Stateful } from '@nexys/material-components';

import * as ProfileMock from '../profile/mock-data';
import * as T from './type';

const mockLoginResponse = {
  profile: ProfileMock.profile,
  permissions: ['app', 'anotherpermission'],
  lang: 'en'
};

export const authenticate = (
  firstName: string,
  lastName: string
): T.LoginResponse => {
  const { profile, permissions, lang } = mockLoginResponse;

  profile.firstName = firstName;
  profile.lastName = lastName;

  Stateful.Credentials.set(profile, permissions, lang);

  return { uuid: 'myuuid' };
};
