import { Id, Uuid } from '@nexys/material-components/dist/common/type';
import * as CrudT from '@nexys/material-components/dist/crud-simple/type';
import * as T from 'interface/lunch/type';
import * as Data from 'interface/lunch/participant';

// data interface (CRUD)
const DataInterface = (
  userId: Id,
  lunchId: Id
): CrudT.DataInterface<T.Participant> => ({
  list: () => Data.list(lunchId),
  detail: id => Data.detail(id, lunchId),
  insert: Data.insert,
  update: Data.update,
  deleteById: (participantId: Id | Uuid) =>
    Data.deleteById(participantId, lunchId, userId)
});

export default DataInterface;
