import {
  Id,
  Uuid,
  UOptionSet
} from '@nexys/material-components/dist/common/type';
import { Stateful } from '@nexys/material-components';

import { withBackend } from 'config';
import * as T from './type';
import * as U from './utils';
import * as MockData from './mock-data';

export const languageOptions: UOptionSet[] = [
  {
    uuid: 'en',
    name: 'English'
  },
  {
    uuid: 'de',
    name: 'Deutsch'
  }
];

export const listGroup = async (): Promise<T.LunchGroup[]> => {
  if (!withBackend) {
    return Promise.resolve(MockData.lunchGroups);
  }

  const r: T.LunchGroup[] = await Stateful.Request.get('/api/lunch/group');
  return r.sort(
    (a, b) =>
      new Date(b.dates.date).getTime() - new Date(a.dates.date).getTime()
  );
};
export const listByEmail = async (email: string): Promise<T.Lunch[]> => {
  const r: any[] = await Stateful.Request.get(
    '/api/lunch/byEmail?email=' + email
  );
  return r.map(x => U.postProcessing(x));
};

export const listByUser = async (userId: Id): Promise<T.Lunch[]> => {
  if (!withBackend) {
    return Promise.resolve(MockData.list.map(d => U.postProcessing(d)));
  }

  const r: any[] = await Stateful.Request.get(
    '/api/lunch/byUser?userId=' + userId
  );
  return r
    .map(x => U.postProcessing(x))
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
};

export const list = async (): Promise<T.Lunch[]> => {
  if (!withBackend) {
    console.log('list');
    return Promise.resolve(MockData.list.map(x => U.postProcessing(x)));
  }

  const r: any[] = await Stateful.Request.get('/api/lunch');
  return r.map(x => U.postProcessing(x));
};

export const insert = (
  data: Partial<T.Lunch>,
  loginId: Id
): Promise<{ id: Id | Uuid }> => {
  if (!withBackend) {
    console.log('insert', data);
    return Promise.resolve({ id: 1 });
  }

  let isValid = true;
  const errors: any = {};
  const fields: (keyof T.Lunch)[] = [
    'name',
    'group_size',
    'date',
    'time',
    'meeting_point'
  ];
  fields.forEach(field => {
    if (!data[field]) {
      isValid = false;
      errors[field] = ['Field is required'];
    }
  });

  if (!isValid) {
    return Promise.reject({ errors });
  }

  const time = data.time!;
  const [hours, minutes] = time.split(':');
  if (Number(hours) < 0 || Number(hours) > 23) {
    return Promise.reject({ errors: { time: ['Hour is not valid'] } });
  }

  if (Number(minutes) < 0 || Number(minutes) > 59) {
    return Promise.reject({ errors: { time: ['Minute is not valid'] } });
  }

  try {
    const date = data.date!;
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));

    const dataForBackend = {
      date: date.toISOString(),
      group_size: data.group_size,
      meeting_point: data.meeting_point,
      name: data.name
    };

    return Stateful.Request.post(
      `/api/lunch/insert?loginId=${loginId}`,
      dataForBackend
    );
  } catch (err) {
    throw err;
  }
};

export const detail = async (id: Id | Uuid): Promise<T.Lunch> => {
  if (!withBackend) {
    console.log('detail', id);
    const item = MockData.list.find(d => d.id === id);
    return Promise.resolve(U.postProcessing(item || MockData.list[0]));
  }

  const r: any = await Stateful.Request.get(`/api/lunch/${id}/detail`);
  return U.postProcessing(r);
};

export const update = (
  data: Partial<T.Lunch>,
  loginId: Id
): Promise<boolean> => {
  if (!withBackend) {
    console.log('update', data);
    return Promise.resolve(true);
  }

  const time = data.time!;
  const [hours, minutes] = time.split(':');
  if (Number(hours) < 0 || Number(hours) > 23) {
    return Promise.reject({ errors: { time: ['Hour is not valid'] } });
  }

  if (Number(minutes) < 0 || Number(minutes) > 59) {
    return Promise.reject({ errors: { time: ['Minute is not valid'] } });
  }

  try {
    const date = data.date!;
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));

    const dataForBackend = {
      date: date.toISOString(),
      group_size: data.group_size,
      meeting_point: data.meeting_point,
      name: data.name,
      emailFrom: data.emailFrom
    };

    return Stateful.Request.post(
      `/api/lunch/update?loginId=${loginId}&id=${data.id!}`,
      dataForBackend
    );
  } catch (err) {
    throw err;
  }
};

export const deleteById = async (
  id: Id | Uuid,
  loginId: Id
): Promise<boolean> => {
  if (!withBackend) {
    console.log('deleteById', id);
    return Promise.resolve(true);
  }

  try {
    const re = await Stateful.Request.post(
      `/api/lunch/delete?loginId=${loginId}&id=${id}`
    );

    if (re.status && re.status === true) {
      return true;
    }

    throw Error('An error occured while trying to delete lunch');
  } catch (err) {
    throw err;
  }
};

export const copy = async (
  lunchId: Id,
  loginId: Id,
  data: Partial<T.CopyLunch>
): Promise<boolean> => {
  if (!withBackend) {
    return Promise.resolve(true);
  }

  const date = data.date!;
  const [hours, minutes] = data.time!.split(':');
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));

  const dataForBackend = {
    name: data.name,
    datetime: date.toISOString()
  };

  try {
    const re = await Stateful.Request.post(
      `/api/lunch/copy?loginId=${loginId}&sid=${lunchId}`,
      dataForBackend
    );

    if (re.status && re.status === true) {
      return true;
    }

    throw Error('Error while trying to copy lunch');
  } catch (err) {
    throw err;
  }
};

export const setAsTemplate = async (lunchId: Id): Promise<boolean> => {
  if (!withBackend) {
    return Promise.resolve(true);
  }

  try {
    const re = await Stateful.Request.get(
      `/api/lunch/setAsTemplate?id=${lunchId}`
    );

    if (re.status && re.status === true) {
      return true;
    }

    throw Error('Error while trying to set lunch as template');
  } catch (err) {
    throw err;
  }
};

export const reset = async (lunchId: Id): Promise<boolean> => {
  if (!withBackend) {
    return Promise.resolve(true);
  }

  try {
    const re = await Stateful.Request.get(`/api/lunch/reset?id=${lunchId}`);

    if (re.status && re.status === true) {
      return true;
    }

    throw Error('Error while trying to reset');
  } catch (err) {
    throw err;
  }
};

export const reshuffle = async (lunchId: Id): Promise<{ msg: string }> =>
  Stateful.Request.get(`/api/lunch/reshuffle?id=${lunchId}`);

export const overview = async (): Promise<T.LunchOverview[]> =>
  Stateful.Request.get(`/api/lunch/overview`);
