import React from 'react';

import { Id } from '@nexys/material-components/dist/common/type';
import { Utils } from '@nexys/material-components';

import Users from './user';

const useStyles = Utils.makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 0, 2, 0)
  },
  listContainer: {
    marginTop: theme.spacing(8)
  }
}));

const OptOutForever = ({ loginId }: { loginId: Id }): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.listContainer}>
        <Users loginId={loginId} />
      </div>
    </div>
  );
};

export default OptOutForever;
