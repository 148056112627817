import { Id } from '@nexys/material-components/dist/common/type';
import { Stateful } from '@nexys/material-components';

import { withBackend } from 'config';

export const getKey = async (loginId: Id): Promise<string> => {
  if (!withBackend) {
    return Promise.resolve('toBeImplemented');
  }

  try {
    const re: { url: string } = await Stateful.Request.get(
      '/api/report/url?loginId=' + loginId
    );
    return re.url;
  } catch (err) {
    throw Error(err);
  }
};
