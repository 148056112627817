import React from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

import { UI, Utils } from '@nexys/material-components';

import TopNav from './top-nav';
import LeftNav from './left-nav';
import Footer from './footer';
import { useHistory } from 'react-router-dom';

const useStyles = Utils.makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'auto'
  },
  main: {
    minHeight: `calc(100vh - 72px)` // we need to subtract the height of footer
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));

interface Props {
  children: JSX.Element | JSX.Element[];
}

function Layout({ children }: Props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();

  // sends a request everytime the page changes
  React.useEffect(() => {
    const unlisten = history.listen((location, action) => {
      console.log('Route changed', location, action);

      fetch('/napi/action-log/insert', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ url: location.pathname })
      });
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <TopNav isOpen={open} onToggle={() => setOpen(!open)} />
      <LeftNav isOpen={open} onClose={() => setOpen(false)} />

      <div className={classes.content}>
        <main className={classes.main}>
          <div className={classes.appBarSpacer} />

          <Container maxWidth="lg" className={classes.container}>
            <UI.Paper>{children}</UI.Paper>
          </Container>
        </main>

        <Footer />
      </div>
    </div>
  );
}

export default Layout;
