import React from 'react';
import { Route, useParams, Switch } from 'react-router-dom';

import { CrudSimple, UI, Utils } from '@nexys/material-components';
import * as CrudT from '@nexys/material-components/dist/crud-simple/type';
import * as T from 'interface/lunch/type';
import * as Data from 'interface/lunch/data';
import * as Links from 'common/link';

import { Id } from '@nexys/material-components/dist/common/type';
import dataDef from './def';
import extras from './extras';
// import UrlBox from './url-box';
import SendEmails from './send-emails';
import Settings from './settings';
import Webflow from './webflow';
import Report from './report';
import OptOutForever from './opt-out-forever';

const useStyles = Utils.makeStyles(theme => ({
  appContainer: {
    '& input[type="number"]': {
      textAlign: 'left !important'
    }
  }
}));

const {
  Buttons: { Back },
  Layout,
  Tabs
} = UI;

// data interface (CRUD)
const DataInterface = (userId: Id): CrudT.DataInterface<T.Lunch> => ({
  list: () => Data.listByUser(userId),
  detail: Data.detail,
  insert: data => Data.insert(data, userId),
  update: data => Data.update(data, userId),
  deleteById: id => Data.deleteById(id, userId)
});

export default () => {
  const { userId } = useParams<{ userId?: string }>();
  const classes = useStyles();

  if (!userId || isNaN(Number(userId))) {
    throw Error('userId not defined');
  }

  const loginId = Number(userId);

  const tabs = [
    {
      label: 'Lunches',
      link: '/lunches',
      Component: <></>
    },
    {
      label: 'Opt-Out Forever',
      link: '/optoutfrvr',
      Component: <OptOutForever loginId={loginId} />
    },
    {
      label: 'Report',
      link: '/report',
      Component: <Report loginId={loginId} />
    }
  ];

  return (
    <>
      <Route path={Links.App.lunch + '/' + userId} exact={true}>
        <Layout.Title title="Lunch" type="pageTitle" />

        <Back onClick={Links.App.lunch} />
      </Route>

      <Route path={`${Links.App.lunch}/${userId}/add`} exact={true}>
        <Layout.Title title="Add new Lunch" type="pageTitle" />
      </Route>

      <Tabs prefix={Links.App.lunch + '/' + userId} tabs={tabs} exact={false} />

      <Switch>
        <Route
          path={`${Links.App.lunch}/:userId/lunches/:lunchId/settings`}
          exact={true}
        >
          <Settings />
        </Route>

        <Route
          path={`${Links.App.lunch}/:userId/lunches/:lunchId/send-emails`}
          exact={true}
        >
          <SendEmails />
        </Route>

        <Route
          path={`${Links.App.lunch}/:userId/lunches/:lunchId/webflow`}
          exact={true}
        >
          <Webflow />
        </Route>

        <div className={classes.appContainer}>
          <CrudSimple.Generic.App
            dataDef={dataDef(Number(userId))}
            Data={DataInterface(Number(userId))}
            extra={extras(Number(userId))}
          />
        </div>
      </Switch>
    </>
  );
};
