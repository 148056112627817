import { Participant } from 'interface/lunch/type';

export const extractFeatures = (
  data: Participant[]
): [string, Map<string, number>][] => {
  const features: Map<string, Map<string, number>> = new Map();

  data.forEach(d => {
    d.features.forEach(f => {
      const fName = features.get(f.name);

      if (fName === undefined) {
        features.set(f.name, new Map());
      }

      const fName2 = features.get(f.name);

      if (fName2 === undefined) {
        throw Error('');
      }

      const ff = fName2.get(f.optionName || '');

      if (!ff) {
        fName2.set(f.optionName || '', 1);
      } else {
        fName2.set(f.optionName || '', ff + 1);
      }
    });
  });

  return Array.from(features);
};
