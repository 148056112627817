import React from 'react';

import { UI, Utils } from '@nexys/material-components';
import { Id } from '@nexys/material-components/dist/common/type';
import * as Tabular from '@nexys/tabular';
import * as Data from 'interface/lunch/participant';
import * as DLunch from 'interface/lunch/data';
import * as OptionsUtils from './bar/utils';
import {
  Shuffle as ShuffleIcon,
  Backup as ExportIcon,
  Remove as TrashIcon
} from '@material-ui/icons';
import { LunchStatus } from 'interface/lunch/type';

const useStyles = Utils.makeStyles(theme => ({
  button: {
    display: 'inline-block',
    marginRight: theme.spacing(2)
  }
}));

const {
  Buttons: { Button },
  FileUpload
} = UI;

const Options = ({
  lunchId,
  statusId,
  onImportSuccess
}: {
  lunchId: Id;
  statusId: LunchStatus;
  onImportSuccess?: () => void;
}) => {
  const { enqueueSnackbar } = Utils.useSnackbar();
  const classes = useStyles();

  const handleDelete = async () => {
    if (
      !window.confirm(
        'Are you sure you would like to delete all participants associated with this lunch? THIS ACXTION IS IRREVERISBLE'
      )
    ) {
      return;
    }

    const url = `/api/lunch/user/delete/all?lunchId=${lunchId}`;

    try {
      await fetch(url);
    } catch (err) {}
  };

  const handleExport = async () => {
    // fetch data
    const data = await Data.list(lunchId);
    // format output

    if (data.length < 1) {
      throw Error('no data');
    }

    const featuresHeaders: string[] = data[0].features.map(x => x.name);

    const headers = [
      'first name',
      'last name',
      'email',
      'group idx',
      ...featuresHeaders
    ];
    const participantsTabular: string[][] = data.map(d => {
      const featuresString: string[] = d.features.map(x => x.optionName || '-');

      return [
        d.firstName,
        d.lastName || '',
        d.email,
        d.lunch.group_idx ? String(d.lunch.group_idx) : '-',
        ...featuresString
      ];
    });
    const content = [headers, ...participantsTabular];

    const worksheet = 'participants';

    const tablularContent: { [k: string]: any } = { content };

    // features
    Array.from(OptionsUtils.extractFeatures(data)).forEach(
      ([feature, values]) => {
        const aValues: [string, number][] = Array.from(values);

        tablularContent['Feature ' + feature] = aValues;
      }
    );

    const x = await Tabular.TabularXlsx.toXlsx(tablularContent, worksheet);

    Utils.saveByteArray(
      'participants.xlsx',
      x,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  };

  const handleImport = (file: File): Promise<any> => {
    const reader = new FileReader();
    reader.onload = a => {
      if (a.target && a.target.result) {
        Data.importParticipants(lunchId, String(a.target.result))
          .then(r => {
            Utils.confirmMessage(
              enqueueSnackbar,
              'Import successful',
              'success'
            );
            if (onImportSuccess) {
              onImportSuccess();
            }
          })
          .catch(err => {
            Utils.confirmMessage(
              enqueueSnackbar,
              'Error while trying to import participants',
              'error'
            );
          });
      }
    };
    reader.readAsText(file);

    return Promise.resolve();
  };

  const handleReshuffle = async () => {
    const { msg } = await DLunch.reshuffle(lunchId);

    alert(msg);
  };

  return (
    <>
      {(statusId === LunchStatus.pending ||
        statusId === LunchStatus.template) && (
        <>
          <div className={classes.button}>
            <FileUpload.UI promise={handleImport} label="Import" />
          </div>
          <p>
            Click <a href="/assets/import-sample.csv">here</a> to download a
            sample import file. Make sure the structure is the exact same and
            that headers are named: <code>firstName</code>
            <code>lastName</code>
            <code>email</code>
          </p>
        </>
      )}
      <div className={classes.button}>
        <Button
          label={
            <>
              <ExportIcon />
              &nbsp; export
            </>
          }
          onClick={handleExport}
        />
      </div>
      <div className={classes.button}>
        {' '}
        <Button
          color="secondary"
          label={
            <>
              <TrashIcon />
              &nbsp; Delete all participants
            </>
          }
          onClick={handleDelete}
        />
      </div>
      {statusId === LunchStatus.closed && (
        <div className={classes.button}>
          <Button
            color={'secondary'}
            label={
              <>
                <ShuffleIcon />
                &nbsp; Reshuffle
              </>
            }
            onClick={handleReshuffle}
          />
        </div>
      )}
    </>
  );
};

export default Options;
